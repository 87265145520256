import { useState } from 'react';
import {
  Select,
  Text,
  Title,
  Checkbox,
  Button,
  Divider
} from '@mantine/core';
import { useMutation } from 'graphql-hooks';
import { ORGANIZER_EMAIL_MUTATION } from '@/network/mutations';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { IconMailFast, IconX } from '@tabler/icons-react';
// eslint-disable-next-line no-unused-vars
import { success, fail } from '@/lib/notifications';

export default function AttendeeEmail({shows, email, eventId, close}) {
  const [show, setShow] = useState(null);
  const [useEmail, setUseEmail] = useState(false);
  const showOpts = [{value: 0, label: 'All shows'}, ...shows];
  const [organizerEmail] = useMutation(ORGANIZER_EMAIL_MUTATION);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: '',
  });

  const handler = async (content) => {
    if (show === null) {
      fail('Oops', 'You must select a show from the dropdown.');
      return false;
    }

    const result = await organizerEmail({variables: { showId: show, eventId, email: useEmail, content }});

    if (result.data) {
      success('Sent!', 'Email has been sent to attendees');
    } else {
      fail('Hmm...', 'Unable to send email. Please try again later.');
    }

    close();
  };

  return (
    <>
      <Title order={3} mb={10}>Email Attendees</Title>
      <Text mb={10}>Select a show and then compose an e-mail which will go to all attendees.</Text>
      <Select
        label="Choose a show"
        placeholder="Select a show"
        data={showOpts}
        value={show}
        onChange={setShow}
        mb={10}
      />
      <Checkbox
        size="lg"
        label={`Use organizer e-mail (${email})`}
        description={`Emails will come from ${useEmail ? email : 'noreply@plainstage.com'}`}
        value={useEmail}
        onChange={(e) => setUseEmail(e.currentTarget.checked)}
      />
      <Divider my={10} />
      <Title order={4} mb={10}>Email Content</Title>
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
      <Button.Group mt={20}>
        <Button color="red" leftIcon={<IconX />} onClick={close}>Cancel</Button>
        <Button color="orange" leftIcon={<IconMailFast />} onClick={() => handler(editor.getHTML())}>Send Email</Button>
      </Button.Group>
    </>
  );
}