import { useState } from 'react';
import { 
  Grid,
  Group,
  Space,
  Button,
  createStyles, 
  Avatar,
  Center,
  PasswordInput,
  Modal,
  Card,
  Text,
  Divider,
  useMantineTheme,
  rem
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from '@/components/AuthProvider';
import UserInput from '@/components/UserInput';
import TZSelect from '@/modules/createEvent/TZSelect';
import CountrySelect from './CountrySelect';
import { useMutation } from 'graphql-hooks';
import { RESET_PW_MUTATION } from '@/network/mutations';
import { GoogleLogin } from '@react-oauth/google';
import { success, fail } from '../../lib/notifications';
import { pickUri } from '../../lib/uris';

const useStyles = createStyles((theme) => ({
  button: {
    backgroundColor: theme.colors.pink[5]
  },
  title: {
    lineHeight: 1,
    fontWeight: 'bolder',
    marginBottom: rem(25)
  },
}));

export default function UserPreferences({ user, handler, refetch }) {
  const [avatar, setAvatar] = useState(user.avatar);
  const [opened, { open, close }] = useDisclosure(false);
  const [currentPw, setCurrentPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [currentError, setCurrentError] = useState(false);
  const [newError, setNewError] = useState(false);
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [resetPw, resetState] = useMutation(RESET_PW_MUTATION);
  const { isMobile, setToken, token } = useAuth();

  const handleTz = (payload) => {
    handler('tz_id', `${payload.tz}`, 'settings'); 
  };

  const handleCountry = (payload) => {
    handler('country', payload, 'user'); 
  };

  const doPwReset = () => {
    if (currentPw === '' || newPw === '') {
      return false;
    }

    if (currentPw !== newPw && getStrength(newPw) === 0) {
      try {
        resetPw({variables: {old: currentPw, new: newPw}, onSuccess: (response) => {
          if (response.data.resetPw) {
            const { jwt } = response.data.resetPw;
            
            setToken(jwt);
            success('Password updated', 'New password successfully set');
          } else {
            fail('Hmm..', 'There was a problem resetting your password. Please try again later.');
          }
        }});
        if (resetState.error) {
          //Only reason an error would come back at this point is if orig password was wrong
          setCurrentError(true);
          setNewError(false);
        }
      } catch (e) {
        //Only reason an error would come back at this point is if orig password was wrong
        setCurrentError(true);
        setNewError(false);
      }
    } else {
      //Only reason to get here is either same pw or strength fail
      setNewError(currentPw === newPw ? "Passwords cannot be the same" : "Password not strong enough");
    }
  };

  const handleGoogle = () => {
    handler('google', true, 'user');

    success('Account Linked', 'Linked your Google account to Plainstage.');
  };

  function getStrength(password) {
    const requirements = [
      { re: /[0-9]/, label: 'Includes number' },
      { re: /[a-z]/, label: 'Includes lowercase letter' },
      { re: /[A-Z]/, label: 'Includes uppercase letter' },
      { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
    ];
    let counter = password.length >= 8 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        counter += 1;
      }
    });

    return counter;
  }

  const assessNewPw = (e) => {
    const val = e.currentTarget.value;

    const str = getStrength(val);
    setNewError(str > 0 ? 'Password not strong enough' : false); 
    setNewPw(val);
  };

  const handleUpload = async (files) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    const url = new URL(`${pickUri().replace('/gql', '')}/upload/avatar/${user.id}`);
    const formData = new FormData();
    formData.append('file', files[0]);

    const fetchOptions = {
      method: "POST",
      body: formData,
      headers
    };

    const results = await fetch(url, fetchOptions);

    if (results.ok) {
      setAvatar(`${pickUri().replace('/gql', '')}/images/avatars/${files[0].name}`);
      // refetch();
      close();
      success('Uploaded', 'User avatar uploaded');
    } else {
      fail('Hmmm...', 'Failed to upload image.');
    }
  };

  return (
    <>
      <Modal opened={opened} onClose={close} title="Change Avatar" centered>
        <Dropzone
          onDrop={handleUpload}
          onReject={(files) => {fail('Uh Oh!', 'Could not upload that file.'); console.log("Rejected files:", files);}}
          maxSize={(3 * 1024 ** 2)}
          maxFiles={1}
          accept={IMAGE_MIME_TYPE}
        >
        <Group position="center" spacing="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size="3.2rem"
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size="3.2rem"
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size="3.2rem" stroke={1.5} />
          </Dropzone.Idle>
          <div>
            <Text size="xl" inline>
              Drag an image here or click to select files
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Attach an image file for your avatar (should not exceed 5mb)
            </Text>
          </div>
        </Group>  
        </Dropzone>
      </Modal>
      <Card withBorder shadow="sm" padding="lg" radius="sm" my={isMobile ? 10 : 20}>
        <Text fz="lg" className={classes.title}>
          Account Settings
        </Text>
        <Grid>
          <Grid.Col span={isMobile ? 12 : 5}>
            <Group>
              <Avatar src={avatar} size={120} radius={120} mb={20} mx="auto" />
              <Button color="orange" onClick={open}>Change Avatar</Button>
            </Group>
            <UserInput label="First Name" placeholder="Your first name" value={user.first_name} field="first_name" handler={handler} />
            <UserInput label="Last Name" placeholder="Your last name" value={user.last_name} handler={handler} field="last_name" />
            <UserInput label="E-Mail" placeholder="test@test.com" value={user.email} handler={handler} field="email" />
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 6}>
            {!isMobile && <Space h={112} />}
            <TZSelect id={user.tz.id} handler={handleTz} />
            <CountrySelect handler={handleCountry} country={user.country} />
          </Grid.Col>
        </Grid>
        <Divider my="md" />
        <Text fw={700}>Authentication</Text>
        <Grid>
          <Grid.Col span={isMobile ? 12 : 5}>
            <Text mb={10}>To reset your password, enter your current and desired passwords below, then click the Reset Password button.</Text>
            <PasswordInput 
              label="Current Password" 
              error={currentError && "Incorrect Password"} 
              value={currentPw} 
              onChange={(e) => setCurrentPw(e.currentTarget.value)} 
            />
            <PasswordInput 
              label="New Password" 
              description="8+ characters, including uppercase, lowercase, number, and special characters" 
              value={newPw} 
              error={newError} 
              onChange={assessNewPw} 
            />
            <Button mt={10} color="orange" onClick={doPwReset}>Reset Password</Button>
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 6}>
            <Text>Connect your social media accounts below.</Text>
            <Center mt={15}>
              <GoogleLogin onSuccess={handleGoogle} onError={() => fail('Hmmm...', 'That did not work. Please try again.')} />
            </Center>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
}