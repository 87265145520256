import { useState } from 'react';
import { Select } from '@mantine/core';
import { countries } from "countries-list";

export default function CountrySelect({handler, country}) {
  const [ctry, setCtry] = useState(country);
  const countryList = Object.keys(countries).map(code => {
    return { value: code, label: `${code} - ${countries[code].name}` };
  });

  const handleChange = (c) => {
    setCtry(c);
    handler(c);
  };

  return (
    <Select
      mt="md"
      size="lg"
      withinPortal
      data={countryList}
      value={ctry}
      placeholder='Country'
      label='Select your country'
      onChange={handleChange}
    />
  )
}