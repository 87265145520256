import { useState } from 'react';
import { 
    createStyles, 
    Select, 
    rem 
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
    root: {
      position: 'relative',
    },
    input: {
      height: rem(54),
      paddingTop: rem(18),
      marginBottom: rem(10)
    },
    label: {
      position: 'absolute',
      pointerEvents: 'none',
      fontSize: theme.fontSizes.xs,
      paddingLeft: theme.spacing.sm,
      paddingTop: `calc(${theme.spacing.sm} / 2)`,
      zIndex: 1,
    },
  }));

export default function UserSelect({label, placeholder, value, field, handler, data}) {
  const { classes } = useStyles();
  const [contents, setContents] = useState(value);
  const type = 'settings';

  const handleUpdate = (val) => {
    if (val === contents) return false;
    if (val !== '') {
      setContents(val);
      handler(field, val, type);
    }    
  };

  return (
    <Select
      mt="md"
      withinPortal
      data={data}
      value={contents}
      placeholder={placeholder}
      label={label}
      classNames={classes}
      onChange={handleUpdate}
    />
  );
}
