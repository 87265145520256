import { useState } from 'react';
import { 
  Group,
  Box, 
  Collapse, 
  ThemeIcon, 
  Text,
  UnstyledButton, 
  rem, 
  Stack
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronRight } from '@tabler/icons-react';

function MainLink({ icon, color, label, link, external }) {
  return (
    <Link to={link} target={external ? "_blank" : "_self"}>
      <UnstyledButton
        sx={(theme) => ({
          '&:hover': {
            backgroundColor: theme.colors.gray[0],
            fontWeight: 700
          },
        })}
      >
        <Group>
          <ThemeIcon color={color} variant="light">{icon}</ThemeIcon>
          <Text size="sm">{label}</Text>
        </Group>
      </UnstyledButton>
    </Link>
  );
}

export default function LinksGroup({ item }) {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const { icon, links, color, label, path } = item;
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(window.location.pathname.includes(path));
  const items = (hasLinks ? links : []).map(data => <MainLink key={data.label} {...data} />);

  return hasLinks ? 
    <>
      <UnstyledButton onClick={() => setOpened((o) => !o)}>
        <Group>
            <ThemeIcon variant="light" color={color} size={30}>{icon}</ThemeIcon>
            <Box fz="sm" style={{width: '55%'}}>{label}</Box>
            <IconChevronRight
              stroke={1.5}
              style={{
                transition: 'transform 200ms ease',
                width: rem(16),
                height: rem(16),
                transform: opened ? 'rotate(-90deg)' : 'none',
                // marginLeft: rem(25)
              }}
            />
        </Group>
      </UnstyledButton>
      <Collapse in={opened}>
        <Stack ml={10} style={{borderLeft: '1px solid #ccc'}}>
          {items}
        </Stack>
      </Collapse>
    </>
  :
  <MainLink {...item} />
}