import { useEffect, useState } from "react";
import { 
  createStyles, 
  Container, 
  Title, 
  Grid,
} from "@mantine/core";
import { useQuery } from "graphql-hooks";
import { EVENT_SEARCH_QUERY } from "@/network/queries";
import Event from "@/components/Event";
import { lpStyles } from "@/lib/styles";
import PSLoader from "@/components/PSLoader";

const useStyles = createStyles(lpStyles);

export default function LPCategories() {
  const [list, setList] = useState([]);
  const { classes } = useStyles();
  const { loading, error, data } = useQuery(EVENT_SEARCH_QUERY, {variables: { past: false}});

  useEffect(() => {
    if (!loading && data?.events) {
      const { events } = data;
      const ids = new Set(events.map(e => +e.id));

      const evtList = Array.from(ids).map(id => events.find(e => +e.id === id));
      setList(evtList.slice(0, 3));
    }

    return () => setList([]);
  }, [loading, error, data]);

  if (loading) return <PSLoader />;

  if (error) return 'There was an error loading events.';

  return (
    <Container className={`${classes.container} ${classes.subcontainer}`}>
      <Title my={10}>Upcoming Events</Title>
      <Grid gutter="xl" grow>
        {list.map((event, idx) => (
          <Grid.Col
            key={`event${idx}`}
            md={12}
            lg={3}
          >
            <Event evt={event} lp={true} />
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
}
