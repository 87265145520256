import dayjs from "dayjs";
import { Badge, Tooltip } from "@mantine/core";
import { fail } from "./notifications";

export const brandColours = {
    pink: [
        "#FEFCFC",
        "#F3D9DA",
        "#EEB3B5",
        "#F28A8D",
        "#FF5A60",
        "#EB4B51",
        "#D64247",
        "#BE3D42",
        "#A04346",
        "#874447",
        "#744445"
      ],
    blue: [
        "#E5E8EB",
        "#CCD3D9",
        "#B4C1CB",
        "#9CB0C1",
        "#83A0BA",
        "#6893B8",
        "#5D84A6",
        "#5A7790",
        "#566A7C",
        "#51606C",
        "#4B565F",
        "#454D54"
      ],
    grey: [
        "#F3F4F6",
        "#D8DBE0",
        "#C1C4CB",
        "#ADB0B8",
        "#9B9FA6",
        "#8B8F96",
        "#7D8087"
      ],
    brown: [
        "#FEFEFE",
        "#E8E6E6",
        "#D4D1CF",
        "#C3BEBB",
        "#B4ACA7",
        "#A79B94",
        "#978B85",
        "#887D78",
        "#79716D",
        "#6B6663",
        "#605C5A",
        "#565352"
      ],
    black: [
        "#606163",
        "#57585A",
        "#4F5052",
        "#47494B",
        "#404245",
        "#3A3C3F",
        "#34363A",
        "#2F3134",
        "#2B2C2E",
        "#272829",
        "#232425",
        "#202021",
        "#1D1D1E"
      ]
};

export const capitalize = (string) => {
    const _string = string.split(" ");
    if (_string.length > 1) {
        return _string
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }

    return _string[0].charAt(0).toUpperCase() + _string[0].slice(1);
};

export const isEmailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const doLogout = () => {
    const ls = window.localStorage;

    ls.removeItem('token');
}

export const formatCurrency = (amount) => {
  let dollar = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  });

  return dollar.format(amount);
};

export const formatStartDate = (date) =>  {
  //Dates come in from Luxon as "Day Month DD, YYYY at HH:MM:SS TIME ZONE"
  //Need to extract out the valid date bits
  return date.split(' ')
      .map(word => word.replace(',', ''))
      .splice(0,6)
      .join(', ')
      .replace(', at,', ' @');
};

export const convertDate = (d) => {
  let dt;
  if (Number.isInteger(parseInt(d, 10))) {
    dt = dayjs(+d);
  } else {
    const parsed = d.split(', ').slice(1, 3).join(' ').replace(' at', '').replace('GMT', ''); 
    dt = dayjs(parsed, "MMMM DD, YYYY h:mm A Z");
  }

  const result = dt.format('ddd MMM D, YYYY @ h:mm A');

  return result;
};

export const cookie = (name, value=null) => {
  if (value) {
    document.cookie = `${name}=${value};secure`;
  } else {
    return document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];
  }
};

export function calcTotal(event, tickets, shows) {
  const ccRate = event.payCC ? 0.029 : 0;
  const psRate = event.payPS ? (event.rate / 100) : 0;
  const donations = event?.donations ? event.donations.reduce((total, d) => total + d.amount, 0) : 0;
  const rate = psRate + ccRate;
  const capacity = shows.reduce((cap, show) => cap + show.cap, 0); 
  if (tickets.length === 0) return [0, 0, 0, 0, capacity];

  const results = tickets.reduce((result, ticket) => {
    result[0]++;
    if (!ticket.comp) {
      result[1]++;
      result[2] += ticket.tier?.price || 0; 
      result[3] += ((event.payPS ? event.perTicket : 0) + (((ticket.tier?.price || 0) * rate)));
      result[4].push(ticket.transactionId);
    }
    return result;
  }, [0, 0, 0, 0, []]);

  //Handle per-transaction fees now.
  const txnFee = new Set(results[4]).size * 0.3;
  results[3] += txnFee;

  results.push(capacity, donations);
  return results;
}

export const calcFees = (evt, price) => {
  const { payPS, payCC, rate, perTicket } = evt;
  let total = price*100;
  let hasFees = false;

  if (price === 0) return 0;

  if (!payPS) {
    const revRate = total * (rate / 100);
    const ticketRate = perTicket * 100;
    total += revRate;
    total += ticketRate;
    hasFees = true;
  }

  if (!payCC) {
    //Stripe charges 2.9% + 0.30
    const ccFee = ((price * 100) * 0.029) + 30;
    total += ccFee;
    hasFees = true;
  }

  return hasFees ? ((total - price*100) / 100) : 0;
};

export const errorMsg = (results, noun, verb) => {
  const { error } = results;
  let msg;

  if (error.graphQLErrors) {
    msg = error.graphQLErrors[0].message;
  }

  if (error.httpError && Object.keys(error.httpError)) {
    msg = error.httpError.statusText;
  }

  const message = `Unable to ${verb} ${noun} - ${msg}`;
  fail('Uh oh!', message);
};

export const ticketDeliveryStatus = (delivery) => {
  const { status = 'undelivered', on = 0 } = delivery;
  let color, text;

  switch (status) {
    case 'undelivered':
      color = 'dark';
      text = 'Undelivered';
      break;
    case 'invalid_email':
      color = 'red';
      text = 'Invalid Email';
      break;
    case 'hard_bounce':
      color = 'red';
      text = 'Bounced';
      break;
    case 'delivered':
      color = 'yellow';
      text = 'Delivered';
      break;
    case 'unique_opened':
    case 'opened':
    case 'proxy_open':
      color = 'green';
      text = 'Opened';
      break;
    default:
      color = 'dark';
      text = 'Unknown';
  }

  return <Tooltip label={`At: ${convertDate(on)}`} color={color} withArrow>
    <Badge color={color} variant='filled'>{text}</Badge>
  </Tooltip>;
};

export const slugify = (value) => {
  const slug = value.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^a-zA-Z0-9 -]/g, '')
    .replace(/\-\-+/g, '-');

  return slug;
};