import { Grid } from '@mantine/core';
import HomeEvents from "../modules/dashboard/HomeEvents";
import HomeTickets from "../modules/dashboard/HomeTickets";

export default function Home() {
    return (
            <Grid>
                <Grid.Col span={6}>
                    <HomeEvents past={false} showTitle={true} />
                </Grid.Col>
                <Grid.Col span={6}>
                    <HomeTickets showTitle={true} />
                </Grid.Col>
            </Grid>
    );
}