import {
  Text,
  Title,
  Card,
  Table,
} from '@mantine/core';
import { convertDate, formatCurrency } from "@/lib/helpers";
import { useAuth } from '@/components/AuthProvider';

export default function Payouts({ payments, currency }) {
  const { isMobile } = useAuth();

  const rows = payments.map(payout => 
    <tr key={`payout${payout.id}`}>
      <td>{formatCurrency(payout.amount, currency)}</td>
      <td>{payout.event.title}</td>
      <td>{convertDate(payout.on)}</td>
    </tr>
  );

  return (
    <Card withBorder shadow="sm" padding="lg" radius="sm" my={isMobile ? 10 : 20}>
      <Title order={2} mb={10}>Payouts</Title>
      <Text>If you are organizing events that collect ticket sales, your payout history will be listed here.</Text>
      {rows.length > 0 ?
        <Table striped highlightOnHover withBorder>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Event</th>
              <th>On</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
        : <Title order={3} mt={20}>You do not have any payouts.</Title>}
    </Card>
  )
}