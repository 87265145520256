export const NAV_QUERY = `query navQuery {
  me {
    name
    email
    avatar
    admin
  }
}`;

export const ME_QUERY = `query meQuery {
  me {
    id
    first_name
    last_name
    name
    email
    admin
    last_login
    avatar
    country
    currency
    tz {
      id
    }
    orgUrl
    orgPage
    emailPrefs {
      marketing
      reminders
    }
    etransfer
    stripe {
      id
      charges
      payouts
    }
    payments {
      id
      amount
      event {
        id
        title
      }
      on
    }
  }
}`;

export const HOME_EVENT_QUERY = `query eventQuery($userId: ID!, $past: Boolean) {
  events(userId: $userId, past: $past) {
      id
      image
      cardImage
      about
      title
      startDate
      slug
      shows {
        startTime
        tz {
          id
        }
      }
      venue {
        name
        street_address
        city
        stprv
      }
  }
}`;

export const ORG_EVENT_QUERY = `query organizerQuery($slug: String!, $past: Boolean) {
  organizer(slug: $slug) {
    name
    avatar 
    email
    orgPage
  }
  events(slug: $slug, past: $past) {
      id
      image
      cardImage
      about
      title
      startDate
      slug
      shows {
        startTime
        tz {
          id
        }
      }
      venue {
        name
        street_address
        city
        stprv
      }
  }
}`;

export const EVENT_SEARCH_QUERY = `
  query searchEventQuery($past: Boolean) {
    events(past: $past) {
      id
      slug
      image
      cardImage
      title
      category {
        name
      }
      shows {
        startTime
      } 
      venue {
        name
        street_address
        city
        stprv
      }
    }
  }
`;

export const HOME_TICKET_QUERY = `query ticketQuery($userId: ID!, $past: Boolean) {
  tickets(userId: $userId, past: $past) {
    id
    show {
      id
      startTime
      venue {
        name
      }
    }    
    event {
      cardImage
      image
      title
    }
  }
}`;

export const SHOW_TICKET_QUERY = `query showTicketQuery($showId: ID!) {
  showTickets(showId: $showId) {
    id
    image
  }
  me {
    id
    email
  }
  show(id: $showId) {
    id
    subtitle
    startTime
    venue {
      name
      street_address
      secondary
      city
      stprv
      postal
    }
    event {
      image
      title
    }
  }
}`;

export const EVENT_QUERY = `query eventQuery($id: ID, $slug: String) {
    event(id: $id, slug: $slug) {
      id
      title
      startDate
      endDate
      image
      cardImage
      published
      private
      about
      payPS
      payCC
      rate
      isOnline
      url
      perTicket
      showContact
      hostedBy
      contactInfo
      slug
      donation
      currency
      private
      fundraising
      shows {
        id
        subtitle
        startTime
        endTime
        count
        cap
        closed
        autoclose
        tz {
          id
        }
        closeInterval
        tiers {
          id
          name
          formattedPrice
          price
          currency
          max
          count
          byDonation
          minimum
          endTime
        }
      }
      owner {
        id
        name
        email
      }
      venue {
        id
        name
        secondary
        street_address
        city
        stprv
        postal
        country
      }
    }
}`;

export const PI_QUERY = `query PiQuery($ticketData: String!, $id: ID!) {
  getPI(ticketData: $ticketData, id: $id)
}`;

export const LP_EVENTS_QUERY = `query lpEventsQuery {
  events {
    id
    title
    startDate
    endDate
    image
    cardImage
    about
    slug
    shows {
      subtitle
      startTime
      endTime
      count
      cap
      tiers {
        id
        name
        formattedPrice
        currency
        max
        byDonation
        minimum
      }
    }
    owner {
      id
      name
    }
    venue {
      id
      name
      secondary
      street_address
      city
      stprv
      postal
      country
    }
  }
}`;

export const SIGNUP_CODE_QUERY = `query SignupCodeQuery($id: ID!, $code: String!) {
  signupCode(id: $id, code: $code)    
}
`;

export const SHOWS_MGR_QUERY = `query ShowsQuery($eventId: ID!) {
  shows(eventId: $eventId) {
    subtitle
    startTime
    endTime
    cap
    venue {
      id
    }
    donation
  }
}`;

export const VENUE_QUERY = `query VenueQuery {
  venues {
    id
    name
    street_address
  }
}`;

export const EVENT_MGR_QUERY = `query eventMgrQuery($id: ID!) {
  event(id: $id) {
    id
    title
    startDate
    endDate
    image
    cardImage
    published
    private
    about
    showContact
    contactInfo
    hostedBy
    payCC
    payPS
    slug
    isOnline
    rate
    perTicket
    fundraising
    private
    donation
    currency
    paid
    followOn
    followOnBody
    showNotify
    showNotifyBody
    category {
        id
      }
    url
    program
    owner {
      id
      name
      email
      etransfer
      stripe {
        id
        payouts
      }
      payments {
        event { 
          id
        }
        amount
        on
      }
    }
    venue {
      id
      name
      secondary
      street_address
      city
      stprv
      postal
      country
    }
    donations {
      user {
        name
        email
      }
      amount
      date
    }
  }
  eventTickets(id: $id) {
    id
    purchaseDate
    comp
    transactionId
    tier {
      id
      name
      price
    }
    show {
      id
      subtitle
      startTime
    }
    user {
      id
      name
      email
    }
    delivery {
      status
      on
    }
  }
  shows(eventId: $id) {
    id
    subtitle
    startTime
    endTime
    count
    cap
    tz {
      id
    }
    closed
    autoclose
    closeInterval
    venue {
      id
      name
    }
    promo {
      id
      code
      price
      max
      startDate
      endDate
    }
    allTiers {
      id
      name
      price
      formattedPrice
      currency
      max
      byDonation
      startTime
      endTime
      minimum
    }
  }
}`;

export const VALIDATE_CODE_QUERY = `query ValidateCodeQuery($code: String!, $showId: ID!) {
    validateCode(code: $code, showId: $showId) {
        price
    }
}`;

export const ADMIN_USERS_QUERY = `query AdminUsersQuery {
  users {
    id
    name
    email
    enabled
    admin
    events {
      id
    }
    tickets {
      refunded
      tier {
        price
      }
    }
    payments {
      amount
    }
  }
}`;

export const ADMIN_EVENTS_QUERY = `query AdminEventsQuery {
  allEvents {
    id
    title
    startDate
    published
    private
    shows {
      id
      startTime
      subtitle
      count
      cap
    }
    owner {
      id
      name
      email
    }
  }
}`;

export const ADMIN_USER_QUERY = `query AdminUserQuery($id: ID!) {
  user(id: $id) {
    id
    first_name
    last_name
    name
    email
    admin
    last_login
    avatar
    country
    currency
    tz {
      id
    }
    enabled
    emailPrefs {
      marketing
      reminders
    }
    etransfer
    logins {
      success
      sso
      pwv
      at
      client
      ip
    }
    tickets {
      id
      event {
        id
        title
      }
      show {
        id
        subtitle
        startTime
      }
      tier {
        id
        name
        price
        formattedPrice
      }
      purchaseDate
      comp
      refunded
      delivery {
        status
        on
      }
    }
    payments {
      id
      event {
        id 
        title
      }
      amount
      on
    }
  }
}`;

export const VENUE_SEARCH_QUERY = `
  query VenueSearch($id: ID, $title: String, $mapboxId: String) {
    venueSearch(id: $id, title: $title, mapboxId: $mapboxId) {
      id  
      name
    }
  }
`;

export const TZ_QUERY = `
  query TZQuery {
    timezones {
      id
      name
      offset
    }
  }
`;

export const CATEGORY_QUERY = `
  query CategoryQuery {
    categories {
      id
      name
    }
  }
`;

export const SLUG_CHECK_QUERY = `
  query SlugCheck($slug: String!, $organizer: Boolean!) {
    slugCheck(slug: $slug, organizer: $organizer)
  }
`;

export const STRIPE_CONNECT_QUERY = `
  query ConnectQuery {
    getStripeLink
  }
`;

export const STRIPE_ACCOUNT_QUERY = `
  query StripeAccountQuery {
    getStripeAccount {
      id
      charges
      details
      requirements {
        disabled_reason
        errors
      }
    }  
  }
`;