import { IconLogout, IconHome, IconMasksTheater, IconTicket, IconSettings2, IconClockEdit, IconTool } from '@tabler/icons';
import { ScrollArea, Stack } from '@mantine/core';
import { useAuth } from '@/components/AuthProvider';
import { IconHelp } from '@tabler/icons-react';
import LinksGroup from '@/modules/app/LinksGroup';
import classes from '@/lib/navbar.css';

const navData = [
  { 
    label: 'Dashboard', 
    icon: <IconHome size={16} />, 
    color: 'blue',
    link: '/app/dashboard', 
    external: false,
    admin: false 
  },
  {
    label: 'Events',
    icon: <IconMasksTheater size={16} />,
    color: 'teal',
    path: '/app/events',
    links: [
      { label: 'Upcoming', link: '/app/events' },
      { label: 'Past', link: '/app/events/past' }
    ],
    external: false,
    admin: false 
  },
  { 
    label: 'Create Event', 
    icon: <IconClockEdit size={16} />, 
    color: 'pink',
    link: '/app/create-event', 
    external: false,
    admin: false 

  },
  { 
    label: 'Tickets', 
    icon: <IconTicket size={16} />, 
    color: 'violet',
    path: '/app/tickets', 
    links: [
      { label: 'Upcoming', link: '/app/tickets' },
      { label: 'Past', link: '/app/tickets/past' }
    ],
    external: false,
    admin: false 
  },
  { 
    label: 'Settings', 
    icon: <IconSettings2 size={16} />,
    color: 'grape',
    path: '/app/settings',
    links: [
      { label: 'User', link: '/app/settings/user'},
      { label: 'Organizer', link: '/app/settings/organizer'}
    ],
    admin: false
  },
  {
    label: 'Help Pages',
    icon: <IconHelp size={16} />,
    color: 'blue',
    link: 'https://help.plainstage.com',
    external: true,
    admin: false
  },
  { 
    icon: <IconTool size={16} />, 
    color: 'orange', 
    label: 'Admin', 
    path: '/app/admin',
    links: [
      {
        label: 'Users',
        link: '/app/admin/users'
      },
      {
        label: 'Events',
        link: '/app/admin/events'
      }
    ],
    external: false,
    admin: true
  },
  {
    label: 'Logout',
    icon: <IconLogout size={16} />,
    link: '/logout',
    external: false,
    admin: false
  },
];

export default function MainLinks() {
  const { admin } = useAuth();

  const links = navData.filter(item => {
    if (item.admin && admin) return true;
    return true;
  }).map((item) => <LinksGroup item={item} key={item.label} />);

  return (
    <nav className={classes.navbar}>
      <ScrollArea className={classes.links}>
        <Stack pl="md">
          {links}
        </Stack>
      </ScrollArea>
    </nav>
  );
}