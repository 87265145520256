import { useState, useEffect, useReducer } from "react";
import { useManualQuery } from "graphql-hooks";
import { TZ_QUERY } from "@/network/queries";
import { Text, Select } from "@mantine/core";
import { IconWorld } from "@tabler/icons-react";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from "dayjs";
import PSLoader from "@/components/PSLoader";
import { fail } from "@/lib/notifications";

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(localizedFormat);

const localTZ = dayjs.tz.guess();

const findTZName = (list, id) => {
  const tz = list.find(t => t.id === id);
  return tz ? tz.name : 'America/Vancouver';
}

const findTZID = (list, name) => {
  const tz = list.find(t => t.name === name);
  return tz ? tz.id : 0;
}

export default function TZSelect({id, handler}) {
  const [loading, setLoading] = useState(true);
  const [timezones, setTimezones] = useState([]);
  const [getTZs] = useManualQuery(TZ_QUERY);
  const [currTime, setCurrTime] = useReducer((prev, next) => {
    const newState = {...prev, ...next};
    let d = dayjs();

    if (next.id) {
      const tz = findTZName(timezones, next.id);
      d = d.tz(tz);
    } 

    newState.timestring = d.format('LT');

    return newState;
  }, {id: 0, timestring: ''});

  useEffect(() => {
    const getTimezones = async() => {
      try {
        const { data } = await getTZs();
        setTimezones(data.timezones);
        const tz_id = id || findTZID(data.timezones, localTZ);
        setCurrTime({ id: tz_id });
      } catch(e) {
        fail("Uh oh!", "There was a problem loading timezones.");
      } finally {
        setLoading(false);
      }
    };

    if (timezones.length === 0) getTimezones();
 }, [timezones, getTZs, id]);

  if (loading) return <PSLoader />;

  return (
    <>
      <Select
        mb={3}
        size="lg"
        withinPortal
        icon={<IconWorld />}
        label="Select the timezone"
        value={currTime.id}
        placeholder="Pick one"
        data={timezones.map(tz => ({ value: tz.id, label: tz.name}))}
        onChange={(e) => {console.log("TZ ID:", e); setCurrTime({id: e}); handler({tz: +e});}}
        searchable
        nothingFound="Nothing found"
        maxDropdownHeight={280}
      />
      <Text size="sm" color="dimmed">Current Time in {currTime.id ? 'selected' : 'current'} timezone: {currTime.timestring}</Text>
    </>
  )
}