import * as React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import LP from './screens/LP';
import Auth from './screens/Auth';
import OuterApp from './screens/OuterApp';
import Signup from './screens/Signup';
import Logout from './modules/app/Logout';
import ErrorPage from './screens/ErrorPage';
import EventPage from './screens/EventPage';
import Main from './screens/Main';
import Dashboard from './screens/Dashboard';
import HomeEvents from './modules/dashboard/HomeEvents';
import ShowTickets from './modules/app/ShowTickets';
import OurStory from './screens/OurStory';
import LPEvents from './screens/LPEvents';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CreateEvent from './screens/CreateEvent';
import EventManager from './modules/eventManager/EventMgr';
import { ForgotPassword } from './screens/ForgotPw';
import { HandleForgotPw } from './screens/HandleForgotPw';
import PrivacyPolicy from './screens/PrivacyPolicy';
import { ContactUs } from './modules/lp/ContactUs';
import ToS from './screens/ToS';
import Test from './screens/Test';
import VerifyCode from './screens/VerifyCode';
import UserSettings from './modules/app/admin/user/UserSettings';
import TicketResults from './screens/TicketResults';
import FreeTickets from './modules/checkout/FreeTicketResults';
import AdminUsers from './modules/app/admin/Users';
import AdminEvents from './modules/app/admin/Events';
import HomeTickets from './modules/dashboard/HomeTickets';
import Settings from './modules/settings/Settings';
import OrgEvents from './screens/OrgEvents';
import StripeRefresh from './screens/StripeRefresh';
// import UserMain from './modules/settings/Main';
import OLGRedirect from './screens/OLGRedirect';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <App><ErrorPage /></App>,
    children: [
      {
        index: true,
        element: <LP />
      },
      {
        path: 'events',
        element: <LPEvents />
      },
      {
        path: "events/:slug",
        element: <EventPage />
      },
      {
        path: "organizer/:slug",
        element: <OrgEvents />
      },
      {
        path: "our-long-goodbye",
        element: <OLGRedirect />
      },
      {
        path: "our-story",
        element: <OurStory />
      },
      {
        path: 'create-event',
        element: <CreateEvent />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: 'contact-us',
        element: <ContactUs />
      },
      {
        path: 'tos',
        element: <ToS />
      },
      {
        path: 'test',
        element: <Test />
      }
    ]
  },
  {
    path: "/stripeRefresh",
    element: <StripeRefresh />
  },
  {
    path: "/signup",
    element: <OuterApp><Signup /></OuterApp>
  },
  {
    path: "/verify",
    element: <OuterApp><VerifyCode /></OuterApp>
  },
  {
    path: "/login",
    element: <OuterApp><Auth /></OuterApp>
  },
  {
    path: "/forgot-password",
    element: <OuterApp><ForgotPassword /></OuterApp>
  },
  {
    path: "/forgot-password/:code",
    element: <OuterApp><HandleForgotPw /></OuterApp>
  },
  {
    path: "/logout",
    element: <OuterApp><Logout /></OuterApp>
  },
  {
    path: "/tickets",
    element: <OuterApp><TicketResults /></OuterApp>
  },
  {
    path: "/tickets/free",
    element: <OuterApp><FreeTickets /></OuterApp>
  },
  {
    path: "/app",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />
      },
      {
        path: "settings/user",
        element: <Settings organizer={false} />
      },
      {
        path: "settings/organizer",
        element: <Settings organizer={true} />
      },
      {
        path: 'events',
        element: <HomeEvents past={false} showTitle={true} />
      },
      {
        path: 'events/past',
        element: <HomeEvents past={true} showTitle={true} />
      },
      {
        path: 'create-event',
        element: <CreateEvent />
      },
      {
        path: 'tickets',
        element: <HomeTickets showTitle={true} past={false} />
      },
      {
        path: 'tickets/past',
        element: <HomeTickets showTitle={true} past={true} />
      },
      {
        path: "events/:id",
        element: <EventManager />
      },
      {
        path: "tickets/:id",
        element: <ShowTickets />
      },
      {
        path: "admin/users",
        element: <AdminUsers />
      },
      {
        path: "admin/events",
        element: <AdminEvents />
      },
      {
        path: "admin/users/:id",
        element: <UserSettings />
      },
    ]
  }
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} /> 
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(console.log);
