import { useState, useEffect } from 'react';
import {
  Grid,
  Title
} from '@mantine/core';
import EmailPreferences from './EmailPreferences';
import UserPreferences from './UserPreferences';
import Payouts from "./Payouts"
// import BillingPreferences from './BillingPreferences';
import { ME_QUERY } from '@/network/queries';
import { useQuery, useMutation } from 'graphql-hooks';
import { UPDATE_PROFILE_MUTATION } from '@/network/mutations';
import { success, fail } from '@/lib/notifications';
import PSLoader from "@/components/PSLoader";
import OrgPreferences from './OrgPreferences';
import StripeConnect from './StripeConnect';

export default function Settings({organizer}) {
  const [updateProfile] = useMutation(UPDATE_PROFILE_MUTATION);
  const [isLoading, setIsLoading] = useState(true);
  const [me, setMe] = useState(null);
  const { loading, error, data } = useQuery(ME_QUERY);

  useEffect(() => {
    if (error) return <Title order={3}>ERROR LOADING YOUR USER SETTINGS</Title>; 

    if (!loading && data?.me) {
      const { me } = data;

      setMe(me);
      setIsLoading(false);
    }

    return () => { setMe(null); setIsLoading(true); };
  }, [loading, error, data, setMe]);

  const handleUpdateProfile = (field, value, type) => {
    const validTypes = ['user', 'settings'];
    const map = {
      first_name: 'first name',
      last_name: 'last name',
      email: 'e-mail address',
      tz_id: 'timezone',
      currency: 'currency',
      country: 'country',
      etransfer: 'e-transfer address',
      reminders: 'e-mail reminders',
      marketing: 'marketing communication preferences',
      org_page: 'organizer page availability',
      org_url: 'organizer page url',
    };

    if (validTypes.includes(type)) {
      updateProfile({variables: {id: me.id, field, value, type}, onSuccess: (res) => {
        me[field] = value;
        success('Profile updated', `Updated ${map[field]} successfully.`);
      }});
    } else {
      fail('Uh Oh!', 'Unable to update profile.');
    }
  };

  if (isLoading) return <PSLoader />;

  return (
    <>
      <Title mb={20}>{organizer ? 'Event Organizer' : 'User'} Settings</Title>
      {organizer ? <>
        <Grid mb={10}>
          <Grid.Col span={12} md={6}>
            <OrgPreferences user={me} handler={handleUpdateProfile} />
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            {/* <BillingPreferences user={me} handler={handleUpdateProfile} /> */}
            <StripeConnect user={me} />
          </Grid.Col>
        </Grid>
        <Payouts payments={me.payments} currency={me.currency} />
      </>
      : 
      <>
        <UserPreferences user={me} handler={handleUpdateProfile} />
        <EmailPreferences user={me} handler={handleUpdateProfile} />
      </>}
    </>
  )
}