import { Card } from '@mantine/core';
import { useAuth } from './AuthProvider';

export default function PSCard({ children }) {
  const { isMobile } = useAuth();

  return (
    <Card withBorder shadow="sm" padding="lg" radius="sm" my={isMobile ? 10 : 20} style={{height: '95%'}}>
      {children}
    </Card>
  )
}