.control {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  color: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);
}

.navbar {
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  height: rem(800px);
  width: rem(300px);
  padding: var(--mantine-spacing-md);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.links {
  flex: 1;
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
}

.link {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  margin-left: var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-sm);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.linksInner {
  /* flex: 1 1 0%; */
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
}

.linkButton {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs);
  border-radius: var(--mantine-radius-sm); 
  color: var(--mantine-color-black-4);
}

.chevron {
  transition: transform 200ms ease;
}

.box {
  display: block;
  align-items: center;
}