import UserSelect from './UserSelect';
import currencyData from 'currency-codes/data';

export default function CurrencySelect({handler, currency}) {
  const currencies = currencyData.map(curr => ({label: `${curr.currency} (${curr.code})`, value: curr.code}))

  return (
    <UserSelect 
      label="Pick your currency" 
      placeholder="Select currency" 
      value={currency} 
      handler={handler} 
      field="currency" 
      data={currencies} 
    />          
  )
}
