import { 
  Header, 
  Burger,
  Group 
} from "@mantine/core";
import Logo from '../../components/Logo';
import { Link } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";

export default function PSHeader({opened, open, close}) {
  const { isMobile } = useAuth();

  return (
    <Header height={70}>
      <Group sx={{ height: "100%" }} px={20}>
        {isMobile && 
          <Burger 
            opened={opened}
            onClick={() => opened ? close() : open()}
            size="sm"
            mr="lg"
          />
        }
        <Link to="/">
          <Logo size="sm" />
        </Link>
      </Group>
    </Header>
  );
}