import { AuthProvider } from "../components/AuthProvider";
import { 
  MantineProvider, 
  AppShell 
} from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import PSHeader from "../modules/app/PSHeader";
import PSNavbar from "../modules/app/PSNavbar";
import { brandColours } from '@/lib/helpers';
import { Navigate, Outlet } from "react-router-dom";
import { Notifications } from '@mantine/notifications';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { pickGoogle } from "@/lib/uris";
import useJwt from "@/lib/useJwt";

export default function Main() {
  const [opened, { open, close }] = useDisclosure(false);
  const { decodedToken, isExpired } = useJwt("localStorage", "token");
  const google = pickGoogle();

  if (isExpired || decodedToken === null) {
    return (<Navigate to="/login" />);
  }

  return (
    <MantineProvider
    withGlobalStyles 
    withNormalizeCSS 
    theme={{
      fontFamily: 'Roboto, sans-serif',
      colors: brandColours, 
      primaryColor: 'pink', 
      primaryShade: 4
    }}
    >
      <Notifications />
        <GoogleOAuthProvider clientId={google.client_id}>
            <AuthProvider>
                <AppShell
                    padding="md"
                    navbar={<PSNavbar opened={opened} open={open} close={close} />}
                    header={<PSHeader opened={opened} open={open} close={close} />}
                    navbarOffsetBreakpoint={"sm"}
                >
                    <Outlet />
                </AppShell>
            </AuthProvider>
        </GoogleOAuthProvider>
    </MantineProvider>
)
}