import { useReducer } from 'react';
import { useParams } from "react-router-dom";
import { 
    Tabs,
} from "@mantine/core";
import { 
  IconBuildingPavilion, 
  IconBrowser, 
  IconTicket, 
  IconSettings, 
  IconCalendar, 
  IconSettingsFilled 
} from '@tabler/icons-react';
import GeneralSettings from './GeneralSettings';
import EventDashboard from "./Dashboard";
import { useQuery, useMutation } from 'graphql-hooks';
import { useAuth } from '../../components/AuthProvider';
import { EVENT_MGR_QUERY } from "../../network/queries";
import { success } from '../../lib/notifications';
import { EVENT_WIZARD_MUTATION } from '../../network/mutations';
import Shows from "./Shows";
import Pricing from "./Pricing";
import Venue from "./Venue";
import EventPage from "./EventPage";
import EventAdmin from './EventAdmin';
import { errorMsg } from '@/lib/helpers';
import PSLoader from '@/components/PSLoader';
const ls = window.localStorage;

export default function EventManager() {
    const { id } = useParams();
    const { isMobile, admin } = useAuth();
    const [activeTab, setActiveTab] = useReducer((prev, next) => {
      const newState = {...prev, ...next};
      
      ls.setItem('tab', next.tab);

      return newState;
    }, { tab: ls.getItem('tab') || 'dashboard' });  
    const { loading, error, refetch, data } = useQuery(EVENT_MGR_QUERY, { variables: { id }});
    const [updateEvent] = useMutation(EVENT_WIZARD_MUTATION);

    if (loading) return <PSLoader />;

    if (error) return 'There was a problem fetching the event. Please contact support@plainstage.com';

    const { event, eventTickets, venues, shows } = data;

    const updateSetting = async (field, value) => {
      const map = {
        pay_cc: 'credit card fees',
        pay_ps: 'Plainstage fees',
        slug: 'event page URL',
        show_contact: 'setting',
        contact_info: 'contact information',
        hosted_by: 'hosting information',
        subtitle: 'show title',
        about: 'event description',
        venue_id: 'venue',
        is_online: 'online venue',
        url: 'venue URL',
        private: 'private event status',
        category_id: 'category',
        donation: 'donation',
        currency: 'currency',
        follow_on: 'follow on emails',
        follow_on_body: 'follow on email content',
        show_notify: 'show notification',
        show_notify_body: 'show notification content'
      };

      const results = await updateEvent({variables: {id: event.id, field, value}});
      if (results.error) {
        errorMsg(results, 'event', 'update');
      } else {
        event[field] = value;
        success('Event Updated', (field === 'published') ? `Your event page is no${value === 'true' ? 'w available' : ' longer published'}.` : `Updated ${map[field]} successfully.`);
      } 
    };

    return (
    <Tabs color="orange" defaultValue="dashboard" value={activeTab.tab} onTabChange={(tab) => setActiveTab({tab})}>
      <Tabs.List grow position={isMobile ? "apart" : "left"}>
        <Tabs.Tab value="dashboard" icon={<IconBrowser size="0.8rem" />}>Dashboard</Tabs.Tab>
        <Tabs.Tab value="general" icon={<IconSettings size="0.8rem" />}>General Settings</Tabs.Tab>
        <Tabs.Tab value="shows" icon={<IconCalendar size="0.8rem" />}>Shows</Tabs.Tab>
        <Tabs.Tab value="venue" icon={<IconBuildingPavilion size="0.8rem" />}>Venue</Tabs.Tab>
        <Tabs.Tab value="eventpage" icon={<IconBrowser size="0.8rem" />}>Event Page</Tabs.Tab>
        <Tabs.Tab value="tickets" icon={<IconTicket size="0.8rem" />}>Pricing</Tabs.Tab>
        {admin && <Tabs.Tab value="admin" icon={<IconSettingsFilled size="0.8rem" />}>Admin</Tabs.Tab>}
      </Tabs.List>

      <Tabs.Panel value="dashboard" pt="xs">
        <EventDashboard event={event} shows={shows} eventTickets={eventTickets} refetch={refetch} />
      </Tabs.Panel>

      <Tabs.Panel value="general" pt="xs">
        <GeneralSettings event={event} refetch={refetch} handler={updateSetting} />
      </Tabs.Panel>

      <Tabs.Panel value="shows" pt="xs">
        <Shows evt={event} venues={venues} shows={shows} refetch={refetch} />
      </Tabs.Panel>

      <Tabs.Panel value="venue" pt="xs">
        <Venue venues={venues} venue={event.venue} refetch={refetch} handler={updateSetting} online={{is_online: event.isOnline, url: event.url}} isWizard={false} />
      </Tabs.Panel>

      <Tabs.Panel value="eventpage" pt="xs">
        <EventPage venues={venues} evt={event} refetch={refetch} handler={updateSetting} />
      </Tabs.Panel>

      <Tabs.Panel value="tickets" pt="xs">
        <Pricing 
          evt={event} 
          tiers={shows.flatMap(show => show.allTiers.map(tier => {tier.show_id = show.id; return tier;}))} 
          shows={shows} 
          handler={updateSetting} 
          refetch={refetch}  
        />
      </Tabs.Panel>

      <Tabs.Panel value="admin" pt="xs">
        <EventAdmin event={event} shows={shows} eventTickets={eventTickets} refetch={refetch} />
      </Tabs.Panel>
    </Tabs>
    )
}