import { 
  createStyles, 
  Card, 
  Image, 
  Text, 
  Title 
} from "@mantine/core";
import PSCard from "./PSCard";
import { convertDate } from "@/lib/helpers";

const useStyles = createStyles(theme => ({
  card: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    cursor: 'pointer'
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
  title: {
    lineHeight: 1,
  },
}));

export default function Ticket({ tickets }) {
  const { classes } = useStyles();
  const num = tickets?.length || 0;
  const { event, show } = tickets[0];
  show.startTime = convertDate(show.startTime);

  return (
    <PSCard onClick={() => window.location = `/app/tickets/${show.id}`}>
      <Card.Section>
        <Image src={event.cardImage || event.image} alt={event.title} imageProps={{style: {objectPosition: '0 0', objectFit: 'cover', width: '100%', height: '200px'}}}/>
      </Card.Section>

      <Card.Section inheritPadding mt={10}>
        <Title order={3} mb={10} className={classes.title}>
          {event.title}
        </Title>
        <Text size="xs" color="dimmed">
          {show.startTime}
        </Text>
      </Card.Section>

      <Card.Section inheritPadding>
        <Text mt="sm" mb="md" color="dimmed" size="xs">
          {show.venue?.name}
        </Text>
        <Text color="dimmed" mb={10}>Number of tickets: {num}</Text>
      </Card.Section>
    </PSCard>
  );
}
