import { useEffect, useReducer, useState } from "react";
import {
  Avatar,
  SimpleGrid,
  Container,
  Title,
  Text,
  createStyles,
  Group,
} from "@mantine/core";
import { useQuery } from "graphql-hooks";
import Event from "../components/Event";
import { ORG_EVENT_QUERY } from "../network/queries";
import PSLoader from "@/components/PSLoader";
import { useParams } from "react-router-dom";

const useStyles = createStyles(theme => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: "#fff",
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default function OrgEvents() {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useReducer((prev, next) => {
    const newState = { ...prev, ...next };
    let ids = [];
    newState.list = [];

    if (next.events && next.events.length) {
      next.events.forEach(evt => {
        if (!ids.includes(evt.id)) {
          newState.list.push(evt);
          ids.push(evt.id);
        }
      });
    }

    newState.show = Boolean(newState.list.length);
    ids = [];

    return newState;
  }, { show: false, list: [], events: [], organizer: { orgPage: true, name: '', email: '', avatar: ''} });

  const { slug } = useParams();
  const { loading, error, data } = useQuery(ORG_EVENT_QUERY, {
    variables: { slug, past: false },
  });
  const { classes } = useStyles();

  useEffect(() => {
    if (error) return <Title order={3}>Oh dear. We were unable to load that organizer's events. Please try back later.</Title>; 

    if (!loading && data?.organizer) {
      const { organizer, events } = data;

      if (!organizer.orgPage) window.location = '/';

      setEvents({events, organizer});
      setIsLoading(false);
    }

    if (loading && !data?.organizer) {
      console.log("DATA:", data);
    }

    return () => { setEvents({ list: []}); setIsLoading(true); };
  }, [loading, error, data]);

  if (isLoading) return <PSLoader />;

  if (error) {
    console.error(error);
    return (
      <div>
        There was an error looking up events. Please try again later.
        {JSON.stringify(error)}
      </div>
    );
  }

  const eventCards = events.list.map(evt => (
    <Event key={`orgEvent${evt.id}`} evt={evt} lp={true} />
  ));

  return (
    <Container size="lg" py="xl">
      <Group spacing="sm">
        <Avatar src={events.organizer.avatar} size={100} radius={100} mb={20} />
        <Title order={2} className={classes.title} mt="sm">
          Upcoming Events for {events.organizer.name}
        </Title>
      </Group>
      <Text>Contact {events.organizer.email} for more information</Text>
      {events.show ? (
        <SimpleGrid
          cols={3}
          spacing="xl"
          mt={50}
          breakpoints={[{ maxWidth: "md", cols: 1 }]}
        >
          {eventCards}
        </SimpleGrid>
      ) :
      <Title align="center" mt={10} order={3}> The organizer has no events to show. </Title>}
    </Container>
  );
}
