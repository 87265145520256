import { 
    UnstyledButton, 
    Group, 
    Avatar, 
    Text, 
    Box, 
    useMantineTheme 
} from '@mantine/core';
import { useQuery } from "graphql-hooks";
import { NAV_QUERY } from "../network/queries";
import PSLoader from './PSLoader';

export default function Navuser() {
    const theme = useMantineTheme();
    const { loading, error, data } = useQuery(NAV_QUERY);

    if (loading) return <PSLoader />;

    if (error) return "ERROR!";
    
    const { me } = data;

    return (
    <Box
        sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
        }}
    >
        <UnstyledButton
        sx={{
            display: 'block',
            width: '100%',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

            '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            },
        }}
        >
            <Group>
                <Avatar
                src={me.avatar}
                radius="xl"
                />
                <Box sx={{ flex: 1 }}>
                    <Text size="sm" weight={500}>
                        {`${me.name}${me.admin ? "(Admin)" : ''}`}
                    </Text>
                    <Text color="dimmed" size="xs">
                        {me.email} 
                    </Text>
                </Box>
            </Group>
        </UnstyledButton>
    </Box>
    );
}