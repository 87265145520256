import { useState } from 'react';
import { 
  Container,
  Text
} from '@mantine/core';
import { pickEnv } from '../lib/uris';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { convertDate } from '@/lib/helpers';
import TZSelect from '@/modules/createEvent/TZSelect';

dayjs.extend(utc)
dayjs.extend(tz)

export default function Test() {
  const [tz, setTz] = useState(dayjs.tz.guess());
  //Keep these, do work below
  const env = pickEnv();
  if (env === 'production') window.location = '/';

  const st = '2024-02-23T03:00:00.000Z';

  const startTime = dayjs.utc(st).tz(tz).format('ddd MMM D, YYYY @ h:mm A');

  const handler = (payload) => setTz(payload.tz);

  return (
    <Container>
      <Text>Timezone: {tz}</Text>
      <Text>With: {startTime}</Text>
      <Text>Without: {convertDate(1708657200000)}</Text>
      <TZSelect handler={handler} />
    </Container>
  );
}
