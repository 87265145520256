import { useState } from 'react';
import { 
  Title,
  Switch,
  Button,
  Text
} from '@mantine/core';
import PSCard from '@/components/PSCard';
import EmailEditor from './EmailEditor';
import { useDisclosure } from "@mantine/hooks";
import { IconMail } from '@tabler/icons-react';

export default function ShowNotificationEmail({evt, handler}) {
  const [showNotify, setShowNotify] = useState(evt.showNotify);
  const [opened, {open, close}] = useDisclosure(false);
  const text = 'If you would like to add an additional message to the ticket buyer with the default notification, please add it here';
  const field = 'show_notify_body';

  return (
    <PSCard>
      <Title order={3}>Upcoming Show Notification E-Mail</Title>
      <Switch
        size="lg"
        onLabel="Yes"
        offLabel="No"
        color="orange"
        label="Send e-mail to ticket buyer 24 hours before show?"
        checked={showNotify}
        onChange={(event) => { setShowNotify(event.currentTarget.checked); handler('show_notify', event.currentTarget.checked.toString());}}
        my={20}
      />
      <Text my={20}>The email is pre-written, but you can add an additional message if you would like. Use the editor button below.</Text>
      <Button color="orange" onClick={open} leftIcon={<IconMail />}>Open Editor</Button>
      <EmailEditor opened={opened} handler={handler} field={field} text={text} close={close} content={evt.showNotifyBody} />
    </PSCard>
  )
}