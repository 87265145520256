import { useState, useEffect, useCallback } from 'react';
import { 
  Grid,
  NumberInput,
  Text,
  rem
} from '@mantine/core';
import PSCard from '@/components/PSCard';
import { calcFees, formatCurrency, convertDate } from '@/lib/helpers';
import Qty from '@/components/checkout/Qty';
import { fail } from '@/lib/notifications';

const displayPrice = (tier) => {
  if (tier.byDonation) {
    return 'Admission is by donation';
  }

  return tier.formattedPrice === "$0.00" ? "Free" : `${tier.formattedPrice} each`
};

export default function Tier({ tier, evt, handler, isMobile, closed }) {
  const [donation, setDonation] = useState(tier.minimum || 0);
  const [qty, setQty] = useState(0);

  const handleDonation = useCallback((val) => {
    if (tier.minimum) {
      if (+val >= tier.minimum) {
        setDonation(val);
      } else {
        fail('Hmmm...', `The minimum donation is $${tier.minimum}`);
      }
    } else {
      setDonation(val);
    }
  }, [tier.minimum, setDonation]);

  useEffect(() => {
    const price = donation || tier.price;
    const fees = calcFees(evt, price);
    const tix = new Array(qty);
    tix.fill({
      tier: tier.name, 
      tierId: tier.id, 
      donation: tier.byDonation, 
      showId: tier.show_id,
      fees, 
      price, 
    });

    handler({tickets: { id: tier.id, tier: tier.name, show: tier.show_id, tickets: tix}}) 
  }, [donation, qty, tier, evt, handler]);

  return (
    <PSCard>
      <Grid>
        <Grid.Col span={9}>
          <Text fz="lg" fw="500" mb="xl">{tier.name}</Text>
          <Text>{displayPrice(tier)}{(!evt.payPS || !evt.payCC) && <sup>*</sup>}</Text>
          {(!evt.payPS || !evt.payCC) && <Text color="dimmed">* - {formatCurrency(calcFees(evt, donation || tier.price))} in fees will be added to the price of each ticket.</Text>}
          {tier.endTime && <Text>Sales end at {convertDate(tier.endTime)}</Text>}
          {tier.byDonation && <Text>Please enter your donation amount to go towards each ticket.</Text>}
          {tier.minimum > 0 && <Text>There is a minimum donation of ${tier.minimum}</Text>}
        </Grid.Col>
        <Grid.Col span={3}>
          {closed ? <Text color="#FF5a60">Sales Closed!</Text> : (tier.max === tier.count) ? <Text color="#FF5a60">Sold Out!</Text> : 
          <Qty tier={tier} setQty={setQty} /> }
          {tier.byDonation && 
            <NumberInput 
              mt={20}
              hideControls
              value={donation || tier.minimum} 
              onChange={setDonation} 
              min={tier.minimum || 0} 
              step={1} 
              styles={{ input: { width: rem(isMobile ? 62 : 125), textAlign: 'center'}}} 
            /> 
           }
        </Grid.Col>
      </Grid>
    </PSCard>
  );
}
