import { useReducer } from 'react';
import { useManualQuery } from "graphql-hooks";
import { SLUG_CHECK_QUERY } from "@/network/queries";
import {
  Switch,
  Button,
  CopyButton,
  Title,
  TextInput
} from '@mantine/core';
import PSCard from '@/components/PSCard';
import { notice } from '@/lib/notifications';
import { pickUrl } from "@/lib/uris";
import { slugify } from '@/lib/helpers';
import { IconLink, IconCopy, IconExternalLink } from '@tabler/icons-react';

export default function OrgPreferences({ user, handler }) {
  const [slugCheck] = useManualQuery(SLUG_CHECK_QUERY);
  const [settings, setSettings] = useReducer((prev, next) => {
    const newState = { ...prev, ...next };

    if (next.slug) newState.slug = slugify(next.slug);

    return newState;
  }, { slug: user.orgUrl, enabled: user.orgPage });

  const handleUrl = async (field, value, type) => {
    const slug = slugify(value || settings.slug);

    const query = await slugCheck({variables: { slug, organizer: false }});

    if (query.data.slugCheck) {
      const newSlug = `${slug}-1`;
      setSettings({ slug: newSlug });
      notice('So you know...', 'There is another organizer page with that URL, so we have modified yours to avoid a conflict.');
      handler('org_url', newSlug, 'settings');
    } else {
      setSettings({slug});
      handler('org_url', slug, 'settings');
    }
  };

  const url = `${pickUrl()}/organizer/`;

  return (
    <PSCard>
      <Title order={3}>
        Organizer Page Settings
      </Title>

      <Switch 
        size="xl"
        label="Enable organizer page"
        onLabel="Enabled"
        offLabel="Disabled"
        color="orange"
        checked={settings.enabled}
        onChange={(event) => { setSettings({enabled: event.currentTarget.checked}); handler('org_page', event.currentTarget.checked.toString(), 'settings');}}
        my={20}
      />
      {settings.enabled && <>
        <TextInput label={`Organizer Page URL (${url}...)`} placeholder={`${url}...`} value={settings.slug} onChange={(evt) => setSettings({slug: evt.currentTarget.value})} />
        <Button.Group mt={10}>
            <Button color="orange" mr={2} leftIcon={<IconLink />} onClick={() => handleUrl()}>Update URL</Button>
            <Button color="blue" mx={2} leftIcon={<IconExternalLink />} onClick={() => window.open(`${url}${settings.slug}`, '_blank')}>Visit Page</Button>
            <CopyButton mx={2} value={`${url}${settings.slug}`}>
              {({ copied, copy }) => (
                <Button leftIcon={<IconCopy />} color={copied ? 'teal' : 'blue'} onClick={copy}>
                  {copied ? 'Copied ' : 'Copy '} URL
                </Button>
              )}
            </CopyButton>
          </Button.Group>
      </>}
    </PSCard>
  );
}