import { useState } from 'react';
import { 
  Grid,
  Title,
  Group,
  Space,
  Button,
  createStyles, 
  Avatar,
  Center,
  PasswordInput,
  Modal,
  Text,
  Divider,
  useMantineTheme,
  rem
} from '@mantine/core';
import PSCard from '@/components/PSCard';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from '@/components/AuthProvider';
import UserInput from '@/components/UserInput';
import TZSelect from '@/modules/createEvent/TZSelect';
import { useMutation } from 'graphql-hooks';
import { RESET_PW_MUTATION } from '@/network/mutations';
import { success, fail } from '@/lib/notifications';
import { pickUri } from '@/lib/uris';

const useStyles = createStyles((theme) => ({
  button: {
    backgroundColor: theme.colors.pink[5]
  },
  title: {
    lineHeight: 1,
    fontWeight: 'bolder',
    marginBottom: rem(25)
  },
}));

export default function UserPreferences({ user, handler, refetch }) {
  const [avatar, setAvatar] = useState(user.avatar);
  const [opened, { open, close }] = useDisclosure(false);
  const [confirmOpened, { open: confirmOpen, close: confirmClose}] = useDisclosure(false);
  const [newPw, setNewPw] = useState('');
  const [newError, setNewError] = useState(false);
  const [enabled, setEnabled] = useState(user.enabled);
  const [admin, setAdmin] = useState(user.admin);
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [resetPw] = useMutation(RESET_PW_MUTATION);
  const { token } = useAuth();

  const doPwReset = () => {
    if (newPw === '') {
      setNewError('Password cannot be blank.');
      return false;
    }

    resetPw({variables: {old: '', new: newPw}, onSuccess: (response) => {
      success('Password updated', 'New password successfully set');
    }});
  };

  const handleTz = (payload) => {
    handler('tz_id', `${payload.tz}`, 'settings');
  };

  const toggleUser = () => {
    handler('enabled', (!enabled).toString(), 'user');
    setEnabled(!enabled);
  };

  const changeRole = () => {
    handler('admin', (!admin).toString(), 'user');
    setAdmin(!admin);
    if (confirmOpen) confirmClose();
  };

  const handleUpload = async (files) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    const url = new URL(`${pickUri().replace('/gql', '')}/upload/avatar/${user.id}`);
    const formData = new FormData();
    formData.append('file', files[0]);

    const fetchOptions = {
      method: "POST",
      body: formData,
      headers
    };

    const results = await fetch(url, fetchOptions);

    if (results.ok) {
      setAvatar(`${pickUri().replace('/gql', '')}/images/avatars/${files[0].name}`);
      // refetch();
      close();
      success('Uploaded', 'User avatar uploaded');
    } else {
      fail('Hmmm...', 'Failed to upload image.');
    }
  };

  return (
    <>
      <Modal opened={confirmOpened} onClose={confirmClose} title="Confirm Promotion" centered>
        <Text my="md">This will give this user permission for all users and events on the site. Are you SURE?</Text>
        <Button color="blue" mr={5} onClick={confirmClose}>Cancel</Button>
        <Button color="green" onClick={changeRole}>I'm Sure</Button>
      </Modal>
      <Modal opened={opened} onClose={close} title="Change Avatar" centered>
        <Dropzone
          onDrop={handleUpload}
          onReject={(files) => {fail('Uh Oh!', 'Could not upload that file.'); console.log("Rejected files:", files);}}
          maxSize={(3 * 1024 ** 2)}
          maxFiles={1}
          accept={IMAGE_MIME_TYPE}
        >
        <Group position="center" spacing="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size="3.2rem"
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size="3.2rem"
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size="3.2rem" stroke={1.5} />
          </Dropzone.Idle>
          <div>
            <Text size="xl" inline>
              Drag an image here or click to select files
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Attach an image file for your avatar (should not exceed 5mb)
            </Text>
          </div>
        </Group>  
        </Dropzone>
      </Modal>
      <PSCard>
        <Text fz="lg" className={classes.title}>
          User Settings
        </Text>
        <Grid>
          <Grid.Col span={5}>
            <Group>
              <Avatar src={avatar} size={120} radius={120} mb={20} mx="auto" />
              <Button color="orange" onClick={open}>Change Avatar</Button>
            </Group>
            <UserInput label="First Name" placeholder="User's first name" value={user.first_name} field="first_name" handler={handler} />
            <UserInput label="Last Name" placeholder="User's last name" value={user.last_name} handler={handler} field="last_name" />
            <UserInput label="E-Mail" placeholder="test@test.com" value={user.email} handler={handler} field="email" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Space h={123} />
            <TZSelect handler={handleTz} />
          </Grid.Col>
        </Grid>
        <Divider my="md" />
        <Text fw={700}>Authentication</Text>
        <Grid>
          <Grid.Col span={5}>
            <Text mb={10}>To reset user's password, enter new password below, then click the Reset Password button.</Text>
              <PasswordInput 
                label="New Password" 
                description="8+ characters, including uppercase, lowercase, number, and special characters" 
                value={newPw} 
                error={newError} 
                onChange={setNewPw} 
              />
            <Button mt={10} color="orange" onClick={doPwReset}>Reset Password</Button>
          </Grid.Col>
          <Grid.Col span={6}>
            <Title order={3}>Account Actions</Title>
              <Center mt={15}>
                <Button.Group orientation="vertical">
                  <Button color={enabled ? "red" : "green"} onClick={toggleUser}>{enabled ? "Disable" : "Enable"} User</Button>
                  <Button color={admin ? "red" : "blue"} onClick={admin ? changeRole : confirmOpen}>{admin ? "Remove" : "Make"} Admin</Button>
                </Button.Group>
            </Center>
          </Grid.Col>
        </Grid>
      </PSCard>
    </>
  );
}