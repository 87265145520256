/* eslint-disable no-unused-vars */
import { useState, useReducer } from 'react';
import {
    Title,
    Tooltip,
    Button,
    SimpleGrid,
    Group,
    Text,
    Modal,
    Badge,
    Box
} from '@mantine/core';
import PSCard from '@/components/PSCard';
import { useDisclosure } from '@mantine/hooks';
import { IconTicket, IconCoin, IconUserPlus, IconQrcode, IconFileTypeCsv, IconFileTypePdf, IconMailShare } from '@tabler/icons-react';
import Tickets from './Tickets';
import Donations from './Donations';
import { useAuth } from '@/components/AuthProvider';
import { pickUri } from '@/lib/uris';
import { convertDate, calcTotal } from '@/lib/helpers';
import { success } from '@/lib/notifications';
import CompTicket from './CompTicket';
import AboutScanner from './AboutScanner';
import EventTotals from './components/EventTotals.js';
import AttendeeEmail from './AttendeeEmailer';

export default function EventDashboard({ event, shows, eventTickets, refetch }) {
  const [compStatus, setCompStatus] = useReducer((prev, next) => {
    const newStatus = {...prev, ...next};

    if (next.success) {
      success('Comp Ticket Created', 'Comp Ticket successfully created');
      refetch();
    }

    return newStatus;
  }, {success: false, num: 0});
  const [salesLoading, setSalesLoading] = useState(false);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [tickets, sales, revenue, fees, txns, capacity, donations] = calcTotal(event, eventTickets, shows);
  const { isMobile, token } = useAuth();
  const [opened, { open, close }] = useDisclosure(false);
  const [scannerOpened, { open: scannerOpen, close: scannerClose }] = useDisclosure(false);
  const [exportOpened, { open: exportOpen, close: exportClose}] = useDisclosure(false);
  const [emailOpened, { open: emailOpen, close: emailClose}] = useDisclosure(false);
  const compShows = shows.map(show => ({value: show.id, label: show.subtitle ? show.subtitle : convertDate(show.startTime)}));
  const compTiers = shows.flatMap(show => show.allTiers.map(tier => ({value: tier.id, label: `${tier.name} (${tier.formattedPrice})`})));
  const calcTicketSegments = () => {
    const ticketSegments = eventTickets.reduce((ledger, ticket) => {
      if (!ledger[ticket.show.id]) {
        ledger[ticket.show.id] = 0;
      }

      ledger[ticket.show.id] += 1;

      return ledger;
    }, {});
    
    return shows.map(show => {
      return { name: show.subtitle ? show.subtitle : convertDate(show.startTime), count: ticketSegments[show.id] || 0, cap: show.cap };
    });
  };

  const ticketsByShow = calcTicketSegments();

  const getReport = (which, clearLoading, format) => {
    const report = which.toLowerCase();
    const baseUrl = pickUri().replace('gql', `report/${report}/${format}`);
    const eventId = window.location.pathname.split('/').pop();
    const url = `${baseUrl}/${eventId}`;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Accept", "application/pdf");

    fetch(url, { method: 'GET', headers })
      .then((res) => res.text())
      .then((url) => { clearLoading(false); window.open(url, '_blank'); });
  };

  const showTotal = () => {
    //$1.50 etransfer fee
    const subtotal = (donations + revenue) - 1.5;

    const result = (event.payCC || event.payPS) ? subtotal - fees :  subtotal;

    return result || 0;
  };

  return (
    <>
      <SimpleGrid mb={20} cols={event.fundraising || isMobile ? 1 : 2}>
        <PSCard padding="lg" shadow="sm" withBorder>
          {(event.donation || event.fundraising) ? <Group grow>
            {!event.fundraising && <EventTotals label="Sales" amount={revenue} />}
            <EventTotals label="Donations" amount={donations} />
          </Group>  :
          <EventTotals label="Sales" amount={revenue} />}
          <Title order={5}>Expected Payout</Title>
          <Group>
            <Text color="dimmed" size={"md"}>${Number.parseFloat(showTotal()).toFixed(2)}</Text>
            {event.paid && <Badge color="lime" size="md" variant="filled">Paid</Badge> }
          </Group>
        </PSCard>   
        {!event.fundraising && <PSCard>
          <Title color="orange" order={2} mb={10}>Tickets Sold</Title>
          <Title order={4} mb={5}>{tickets} sold</Title>
          <Text>{capacity - tickets} / {capacity} Available</Text>
          <Text>{eventTickets.filter(t => t.comp).length} Comps</Text>
        </PSCard>}
      </SimpleGrid> 
      {!event.fundraising && <SimpleGrid mb={20} cols={isMobile ? 1 : 2}>
        <PSCard>
          <Title color="orange" order={2} mb={10}>Sales by Show</Title>
          {ticketsByShow.map((show, idx) => <Group position="apart" key={`salesShow${idx}`}>
              <Text>{show.name}</Text>
              <Text>{show.count} / {show.cap}</Text>
          </Group>)}
        </PSCard>
        <PSCard>
          <Title color="orange" order={2} mb={10}>Tools</Title>
          <Button.Group orientation="vertical">
            <Button color="green" leftIcon={<IconUserPlus />} mb={3} onClick={open}>Issue Comp Ticket</Button>
            {(sales > 0) && (
              <>
                <Tooltip label="Unified report of ticket purchases and sales / payouts" withinPortal>
                  <Button color="orange" loading={salesLoading} leftIcon={<IconCoin />} mb={3} onClick={() => { setSalesLoading(true); getReport('Sales', setSalesLoading);}}>Download Sales Report</Button>
                </Tooltip>
                <Tooltip label="Simple report of ticket purchases" withinPortal>
                  <Button color="blue" mb={3} loading={ticketLoading} leftIcon={<IconTicket />} onClick={() => { setTicketLoading(true); getReport('Ticket', setTicketLoading);}}>Download Ticket Report</Button>
                </Tooltip>
                <Tooltip label="Use the ticket scanner at your show" withinPortal>
                  <Button color="yellow" leftIcon={<IconQrcode />} onClick={scannerOpen}>Ticket Scanner</Button>
                </Tooltip>
              </>
            )}
          </Button.Group>
        </PSCard>        
      </SimpleGrid>}
      <Box mt={isMobile ? 30 : 40}>
        {event.fundraising ? <Donations donations={event.donations} /> : <Tickets shows={shows} tickets={eventTickets} refetch={refetch} />}
      </Box>
      <Modal opened={opened} onClose={close} title="Issue Comp Ticket">
        <CompTicket close={close} shows={compShows} tiers={compTiers} worked={setCompStatus} />
      </Modal>
      <Modal opened={scannerOpened} onClose={scannerClose} title="Plainstage Ticket Scanner" size="75%">
        <AboutScanner close={scannerClose} />
      </Modal>
      <Modal opened={emailOpened} fullScreen={isMobile} onClose={emailClose} title="Email Attendees" size="50%">
        <AttendeeEmail shows={compShows} eventId={event.id} email={event.contactInfo} tickets={eventTickets} close={emailClose} />
      </Modal>
      <Modal opened={exportOpened} onClose={exportClose} title="Plainstage Sales Report" size="50%">
        Please choose the format for downloading your sales report:

        <Group grow>
          <Button color="blue" mb={10} loading={salesLoading} leftIcon={<IconFileTypeCsv />} onClick={() => {setSalesLoading(true); getReport('Sales', setSalesLoading, 'csv'); exportClose();}}>CSV</Button>
          <Button color="blue" mb={10} loading={salesLoading} leftIcon={<IconFileTypePdf />} onClick={() => {setSalesLoading(true); getReport('Sales', setSalesLoading, 'pdf'); exportClose();}}>PDF</Button>
        </Group>
      </Modal>
    </>
  )
}