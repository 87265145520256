import { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import { HOME_TICKET_QUERY } from "../../network/queries";
import { 
    SimpleGrid, 
    Paper,
    Title,
} from "@mantine/core";
import Ticket from "../../components/Ticket";
import { useAuth } from "../../components/AuthProvider";
import PSLoader from "@/components/PSLoader";

export default function HomeTickets({past, showTitle}) {
  const [isLoading, setIsLoading] = useState(true);
  const [ticketData, setTicketData] = useState(null);
  const { userId } = useAuth();

  const { loading, data, error } = useQuery(HOME_TICKET_QUERY, { variables: { userId, past}});

  useEffect(() => {
    const distinct = {};

    if (error) return <Title order={3}>ERROR LOADING TICKETS</Title>; 

    const tickets = data?.tickets;

    if (tickets && tickets.length) {
      tickets.forEach(t => {
        if (!distinct[t.show.id]) {
          distinct[t.show.id] = [];
        }

        distinct[t.show.id].push(t);
      });

      setTicketData(distinct);
    }

    setIsLoading(false);
    return () => { setTicketData(null); setIsLoading(true); };
  }, [loading, error, data]);

  return (
    <Paper shadow="sm" p="md">
      {showTitle && <Title color="#FF5a60" mb={20}>{past ? "Past" : "Upcoming"} Tickets</Title>}
      {isLoading && <PSLoader />}
      {(!isLoading && ticketData) ? 
        <SimpleGrid cols={2}>
            {Object.values(ticketData).map(show => <Ticket key={`ticket${show[0].show.id}`} tickets={show} />)}
        </SimpleGrid>
      : <Title order={3}>You have no {past ? "previously purchased" : "upcoming"} tickets.</Title>}
    </Paper>
    )
}
