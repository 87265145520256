import { useState } from "react";
import { 
  Grid, 
  Title,
  TextInput,
  Image,
  Group,
  Checkbox,
  Text,
  Button,
  Switch,
  CopyButton,
  Modal,
  rem,
  useMantineTheme,
  Box,
  Tooltip
} from "@mantine/core";
import PSCard from "@/components/PSCard";
import { useManualQuery, useMutation } from "graphql-hooks";
import { SLUG_CHECK_QUERY } from "@/network/queries";
import { GEN_EVENT_QR_MUTATION } from "@/network/mutations";
import { Carousel } from '@mantine/carousel';
import { useDisclosure } from "@mantine/hooks";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX, IconPhoto, IconExternalLink, IconLink, IconBook, IconCopy, IconIdBadge, IconQrcode } from '@tabler/icons-react';
import { success, fail, notice } from "../../lib/notifications";
import { pickUrl, pickUri } from "../../lib/uris";
import { useAuth } from "../../components/AuthProvider";
import Event from '../../components/Event';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';

export default function EventPage({evt, handler, refetch}) {
  const { isMobile, token } = useAuth();
  const [opened, {open, close}] = useDisclosure(false);
  const [publishOpened, {open: publishOpen, close: publishClose}] = useDisclosure(false);
  const [cardOpened, {open: cardOpen, close: cardClose}] = useDisclosure(false);
  const [qrOpened, {open: qrOpen, close: qrClose}] = useDisclosure(false);
  const [showContact, setShowContact] = useState(evt.showContact);
  const [useOrganizer, setUseOrganizer] = useState(true);
  const [slide, setSlide] = useState(0);
  const [qr, setQR] = useState(null);
  const [contact, setContact] = useState(evt.contactInfo);
  const [hostedBy, setHostedBy] = useState(evt.hostedBy || evt.owner.name);
  const [slug, setSlug] = useState(evt.slug);
  const [isPublished, setIsPublished] = useState(evt.published);
  const [isPrivate, setIsPrivate] = useState(evt.private);
  const [slugCheck] = useManualQuery(SLUG_CHECK_QUERY);
  const [eventQR] = useMutation(GEN_EVENT_QR_MUTATION);
  const theme = useMantineTheme();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: evt.about,
  });
  const { owner } = evt;

  const handleSlug = async() => {
    const query = await slugCheck({variables: { slug, organizer: true }});

    if (query.data.slugCheck) {
      const newSlug = `${slug}-1`;
      setSlug(newSlug);
      notice('So you know...', 'There is another event with that URL, so we have modified yours to avoid a conflict.');
      handler('slug', newSlug);
    } else {
      handler('slug', slug);
    }
  };

  const handleUpload = async (files) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    const url = new URL(`${pickUri().replace('/gql', '')}/upload/${slide === 0 ? 'eventImage' : 'eventCardImage'}/${evt.id}`);
    const formData = new FormData();
    formData.append('file', files[0]);

    const fetchOptions = {
      method: "POST",
      body: formData,
      // mode: 'no-cors',
      headers
    };

    const results = await fetch(url, fetchOptions);

    if (results.ok) {
      evt.image = files[0].name;
      refetch();
      close();
      success('Uploaded', 'Event image uploaded');
    } else {
      fail('Hmmm...', 'Failed to upload image.');
    }
  };

  const handlePublish = (event) => { 
    if (owner.stripe.id && owner.stripe.payouts) {
      setIsPublished(event.currentTarget.checked); 
      handler('published', event.currentTarget.checked.toString());
    } else {
      publishOpen();
    }
  };

  const genQR = async () => {
    if (qr) {
      qrOpen();
      return true;
    }

    const results = await eventQR({variables: {id: evt.id}});

    if (results.data.getEventQR) {
      setQR(results.data.getEventQR);
      qrOpen();
    } else {
      fail("Uh oh!", "Failed to generate QR for event.");
    }
  };

  return (
    <>
    <Grid>
      <Grid.Col span={12}>
        <PSCard>
          <Carousel
            mx="auto"
            height={250}
            onSlideChange={setSlide}
            styles={{
              control: {
                '&[data-inactive]': {
                  opacity: 0,
                  cursor: 'default',
                },
              },
            }}
          >
            <Carousel.Slide>
              <Title order={3} mb={10}>Event Page Image</Title>
              <Text mb={10}>This is the image that will be shown on your event page.</Text>
              <Image src={evt.image} width={'100%'} height="250" />
            </Carousel.Slide>
            <Carousel.Slide>
              <Title order={3} mb={10}>Event Card Image</Title>
              <Text mb={10}>This is the image that will be shown in the card view (such as the home page and in event search).</Text>
              <Image src={evt.cardImage} height="250" />
            </Carousel.Slide>
          </Carousel>
          <Button mt={10} color="orange" onClick={open}>Change Image</Button>
        </PSCard>
      </Grid.Col>
      <Grid.Col md={isMobile ? 12 : 6}>
        <PSCard>
          <Title order={2} mb={10}>Availability</Title>
          <Group grow>
            <Box>
              <Text mb={10}>Make your event page live.</Text>
              <Text mb={10} color="dimmed" fz="sm">You can still make changes after you publish your event.</Text>
              <Switch 
                size="xl"
                onLabel="Published"
                offLabel="Draft"
                color="orange"
                checked={isPublished}
                onChange={handlePublish}
                my={20}
              />
            </Box>
            <Box>
              <Text mb={10}>Make your event page private.</Text>
              <Text mb={10} color="dimmed" fz="sm">When a page is private, it can still be accessed by anyone with the URL, but will not show up in public search.</Text>
              <Switch
                size="xl"
                onLabel="Private"
                offLabel="Public"
                disabled={!isPublished}
                color="orange"
                checked={isPrivate}
                onChange={(event) => { setIsPrivate(event.currentTarget.checked); handler('private', event.currentTarget.checked.toString());}}
                my={20}
              />
            </Box>
          </Group>
        </PSCard>
      </Grid.Col>
      <Grid.Col md={isMobile ? 12 : 6}>
        <PSCard>
          <Title order={2} mb={10}>Event Page URL</Title>
          <Text color="dimmed" size="sm">https://plainstage.com/events/...</Text>
          <Grid>
            <Grid.Col span={10}>
              <TextInput value={slug} onChange={(e) => setSlug(e.target.value)} mb={10} />
            </Grid.Col>
            <Grid.Col span={2}>
              <Tooltip label="Generate QR Code for event">
                <Button color={"orange"} onClick={genQR}><IconQrcode /></Button>
              </Tooltip>
            </Grid.Col>
          </Grid>
          <Button.Group orientation={isMobile ? "vertical" : "horizontal"}>
            <Button color="orange" ml={isMobile ? 2 : 0} mr={2} mb={isMobile ? 2 : 0} leftIcon={<IconLink />} onClick={() => handleSlug()}>Update URL</Button>
            <Button color="orange" mx={2} leftIcon={<IconIdBadge />} mb={isMobile ? 2 : 0} onClick={cardOpen}>Preview Event Card</Button>
            <Button color="blue" mx={2} leftIcon={<IconExternalLink />} mb={isMobile ? 2 : 0} onClick={() => window.open(`${pickUrl()}/events/${slug}`, '_blank')}>Visit Page</Button>
            <CopyButton ml={2} mr={isMobile ? 0 : 2} value={`https://plainstage.com/events/${slug}`}>
              {({ copied, copy }) => (
                <Button leftIcon={<IconCopy />} color={copied ? 'teal' : 'blue'} onClick={copy}>
                  {copied ? 'Copied ' : 'Copy '} URL
                </Button>
              )}
            </CopyButton>
          </Button.Group>
        </PSCard>
      </Grid.Col>
      <Grid.Col span={12}>
        <PSCard>
          <Title order={2} mb={10}>Event Contact</Title>
          <Group>
            <Checkbox 
              checked={showContact} 
              onChange={(e) => { handler('show_contact', e.target.checked.toString()); setShowContact(e.target.checked); }} 
            />
            <Text>Show contact info on event page</Text>
          </Group>
          <Group>
            <Checkbox 
              checked={useOrganizer} 
              disabled={!showContact} 
              onChange={(e) => { handler('contact_info', e.target.checked ? '' : contact); setUseOrganizer(e.target.checked); }} 
            />
            <Text>Use Organizer Contact Info</Text>
          </Group>
          <TextInput 
            mt={15} 
            label="This will be the e-mail or phone number visible on the event page." 
            value={contact} 
            disabled={!showContact && !useOrganizer} 
            placeholder="Enter contact info (phone / email)" 
            onChange={(e) => setContact(e.target.value)} 
            onBlur={() => handler('contact_info', contact)} 
          />
          <TextInput 
            mt={15} 
            label="This will be the name listed as 'Hosted By' on the event page." 
            value={hostedBy} 
            disabled={!showContact} 
            placeholder="Enter organizer name" 
            onChange={(e) => setHostedBy(e.target.value)} 
            onBlur={() => handler('hosted_by', hostedBy)} 
          />
        </PSCard>
      </Grid.Col>
      <Grid.Col span={12}>
        <PSCard>
          <Title order={2} mb={10}>Event Description</Title>
          <RichTextEditor editor={editor}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>
          <Button color="orange" leftIcon={<IconBook />} onClick={() => handler('about', editor.getHTML())} mt={20}>Update Description</Button>
        </PSCard>
      </Grid.Col>
    </Grid>
    <Modal opened={opened} onClose={close} centered title={`New Event ${slide === 0 ? 'Page' : 'Card'} Image`}>
      <Dropzone
          onDrop={handleUpload}
          onReject={(files) => fail('Uh Oh!', 'Could not upload that file.')}
          maxSize={(3 * 1024 ** 2)}
          maxFiles={1}
          accept={IMAGE_MIME_TYPE}
        >
        <Group position="center" spacing="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size="3.2rem"
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size="3.2rem"
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size="3.2rem" stroke={1.5} />
          </Dropzone.Idle>
          <div>
            <Text size="xl" inline>
              Drag an image here or click to select files
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Attach an image file for your event (should not exceed 5mb)
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Ideal dimensions: 851px x 315px (social media cover photo)
            </Text>
          </div>
        </Group>  
      </Dropzone>
    </Modal>  
    <Modal opened={cardOpened} onClose={cardClose} centered title="Preview Event Card">
      <Event evt={evt} lp={true} />
    </Modal>
    <Modal opened={qrOpened} onClose={qrClose} centered title="Event QR Code">
      <Text>Here is a QR code for your event. Feel free to share this on social media.</Text>
      <Text>Just right-click on the image and choose 'Save Image As..' to download it to your computer.</Text>
      <Image src={qr} />
    </Modal>
    <Modal opened={publishOpened} onClose={publishClose} centered title="Stripe Not Setup Yet!">
      <Title order={3} mb={10}>Stripe Account Required!</Title>
      <Text mb={10}>In order to publish your event and receive payouts, you must configure a Stripe account. Please visit the Organizer Settings page.</Text>
      <Button size="lg" color="#ff5a60" onClick={() => window.location = '/app/settings/organizer'}>Go to Organizer Settings</Button>
    </Modal>
  </>
)}
