import { useState } from "react";
import {
    Title,
    SimpleGrid,
    Text,
    TextInput,
    Checkbox,
    Paper,
} from '@mantine/core';
import PSCard from "@/components/PSCard";
import VenueSearch from "@/modules/createEvent/VenueSearch";
import { useAuth } from "@/components/AuthProvider";

export default function Venue({ venue, handler, online }) {
  const { isMobile } = useAuth();
  const [isOnline, setIsOnline] = useState(online.is_online);
  const [selectedVenue, setSelectedVenue] = useState(venue);
  const [url, setUrl] = useState(online.url || '');

  const handleVenue = (v) => {
    handler('venue_id', v.venue);
    if (v.venueName) {
      v.name = v.venueName;
    }

    if (v.address) {
      v.street_address = v.address;
    }
    setSelectedVenue(v);
  };

  return (
    <Paper mt={10} p="md">
      <SimpleGrid cols={isMobile ? 1 : 3} mb={20}>
        <PSCard>
          <Title order={3} mb={20}>Current Venue</Title>
          {isOnline && <Text mb={10}>Event is being held online.</Text>}
          {!isOnline && (<><Title order={5}>{selectedVenue?.name}</Title>
          <Text>{selectedVenue?.street_address}</Text>
          {selectedVenue?.secondary && <Text>{selectedVenue.secondary}</Text>}
          <Text>{selectedVenue?.city} {selectedVenue?.stprv} {selectedVenue?.postal}</Text></>)}
        </PSCard>
        <PSCard>
          <VenueSearch handler={handleVenue} isWizard={false} />
          <Text mb={10}>You can search by name or address of the venue.<br />If your event will be at multiple venues, you can set a venue for each show on the Shows tab above.</Text>
        </PSCard>
        <PSCard>
          <Title order={4} mb={10}>Online Event</Title>
          <Checkbox
            checked={isOnline}
            onChange={(e) => {handler('is_online', `${e.target.checked}`); setIsOnline(e.target.checked);}}
            label="Is this event being held online?"
          />
          <TextInput
            label="URL"
            placeholder="Enter URL of online event"
            disabled={!isOnline}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            onBlur={(e) => { handler('url', e.target.value); setUrl(e.target.value); }}
          />
        </PSCard>
      </SimpleGrid>
   </Paper>
  )
}