import EventWizard from '@/modules/createEvent/EventWizard';
import {
  Container
} from '@mantine/core';

export default function CreateEvent() {
  return (
    <Container>
      <EventWizard />
    </Container>
  )

}