import { 
    createStyles, 
    Text,
} from '@mantine/core';
import PSCard from '@/components/PSCard';
import currencyData from 'currency-codes/data';
import UserSelect from '@/components/UserSelect';
import UserInput from '@/components/UserInput';

const useStyles = createStyles((theme) => ({
  item: {
    '& + &': {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `0.1rem solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    '& *': {
      cursor: 'pointer',
    },
  },

  title: {
    lineHeight: 1,
    fontWeight: 'bolder',
    marginBottom: '0.45rem'
  },
}));

export default function BillingPreferences({user, handler}) {
  const { classes } = useStyles();
  const currencies = currencyData.map(curr => ({label: `${curr.currency} (${curr.code})`, value: curr.code}))

  return (
    <PSCard>
      <Text fz="lg" className={classes.title}>
        Billing Settings
      </Text>

      <UserSelect label="Pick user's currency" placeholder="Select currency" value={user.currency} handler={handler} field="currency" data={currencies} />          
      <UserInput label="User's E-Transfer address" placeholder="E-transfer email address" value={user.etransfer || user.email} field="etransfer" handler={handler} />
    </PSCard>
  );
}