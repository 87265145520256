import { Navbar } from '@mantine/core';
import MainLinks from './MainLinks'; 
import Navuser from '../../components/Navuser';

export default function PSNavbar({ opened }) {
  return (
    <Navbar p="xs" hiddenBreakpoint="sm" hidden={!opened} width={{ base: 250 }}>
      <Navbar.Section grow mt="md">
        <MainLinks />
      </Navbar.Section>
      <Navbar.Section>
        <Navuser />
      </Navbar.Section>
    </Navbar>
  );
}