// import { useState, useEffect } from 'react';
import { 
    Title,
    Text,
    Button,
    Tooltip,
} from '@mantine/core';
import PSCard from '@/components/PSCard';
import { useManualQuery, useMutation } from 'graphql-hooks';
import { STRIPE_CONNECT_QUERY } from '@/network/queries';
import { CREATE_STRIPE_MUTATION } from '@/network/mutations';
import { IconBrandStripe } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { success, notice, fail } from '@/lib/notifications';

export default function StripeConnect({ user }) {
  const [getStripe] = useManualQuery(STRIPE_CONNECT_QUERY);
  const [createStripe] = useMutation(CREATE_STRIPE_MUTATION);

  const handleExisting = async () => {
    const { data } = await getStripe();

    if (data) {
      success('Done!', 'Sending you to Stripe to finish the process');
      window.open(data.getStripeLink, '_blank');
    } else {
      fail("Uh oh!", "Failed to generate Stripe link");
    }
  };

  const handleCreate = async () => {
    const newStripe = await createStripe();

    if (newStripe === 'existing') {
      notice("Interesting...", "It looks like you already have a Stripe account. Please click on the Connect existing option");
    } else {
      await handleExisting();
    }
  };

  return (
    <PSCard>
      <Title order={3} mb={10}>Connect to Stripe</Title>
      <Text>Plainstage uses <Link to="https://stripe.com" target="_blank" style={{textDecoration: 'none', color: '#ff5a60'}}>Stripe&trade;</Link> for payment processing. In order to facilitate payouts, please either connect your existing Stripe account or create a new one using the options below.</Text>

      {user.stripe.charges && user.stripe.payouts ? <Text mt={20}>Please visit <Link to="https://dashboard.stripe.com" target="_blank" style={{textDecoration: 'none', color: '#ff5a60'}}>your Stripe dashboard</Link> to manage your account.</Text>
      :
      <Button.Group mt={10}>
        {!user.stripe.id && <Tooltip label="Create a new Stripe account" withArrow>
          <Button size="md" mr={3} leftIcon={<IconBrandStripe />} onClick={handleCreate}>Create account</Button>
        </Tooltip>}
        <Tooltip label="Connect an existing Stripe account" withArrow>
          <Button size="md" leftIcon={<IconBrandStripe />} onClick={handleExisting}>Connect account</Button>
        </Tooltip>
      </Button.Group>}
    </PSCard>
  )
}