import { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import { HOME_EVENT_QUERY } from "../../network/queries";
import { 
  SimpleGrid, 
  Paper,
  Title,
  createStyles
} from "@mantine/core";
import { useAuth } from "../../components/AuthProvider";
import Event from "../../components/Event";
import PSLoader from "@/components/PSLoader";

const useStyles = createStyles((theme) => ({
  sectionTitle: {
    color: "#EB4B51"
  }
}));

export default function HomeEvents({past, showTitle}) {
  const [isLoading, setIsLoading] = useState(true);
  const [eventData, setEventData] = useState(null);
  const { userId } = useAuth();
  const { classes } = useStyles();

  const { loading, data, error } = useQuery(HOME_EVENT_QUERY, {variables: { userId, past }});

  useEffect(() => {
    const ids = [];
    const distinct = [];

    if (error) return <Title order={3}>ERROR LOADING EVENTS</Title>; 

    const events = data?.events;

    if (events && events.length) {
      events.forEach(evt => {
        if (!ids.includes(evt.id)) {
          distinct.push(evt);
          ids.push(evt.id);
        }
      });

      setEventData(distinct);
    }

    setIsLoading(false);
    return () => { setEventData(null); setIsLoading(true); };
  }, [loading, error, data]);

  return (
    <Paper shadow="sm" p="md">
      {showTitle && <Title className={classes.sectionTitle} mb={20}>{past ? "Past" : "Upcoming"} Events</Title>}
      {isLoading && <PSLoader />}
      {(!isLoading && eventData) ? 
        <SimpleGrid cols="3">
          {eventData.map(evt => <Event key={`Event-${evt.id}`} evt={evt} lp={false} />)}
        </SimpleGrid>
        : <Title order={3}>You have no {past ? "past" : "upcoming"} events.</Title>}
    </Paper>
  )
}