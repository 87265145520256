import { useEffect } from 'react';
import { useQuery, useManualQuery } from 'graphql-hooks';
import { STRIPE_CONNECT_QUERY, STRIPE_ACCOUNT_QUERY } from '@/network/queries';
import PSLoader from '@/components/PSLoader';
import { fail } from '@/lib/notifications';

export default function StripeRefresh() {
  const [getStripeLink] = useManualQuery(STRIPE_CONNECT_QUERY);
  const { loading, error, data } = useQuery(STRIPE_ACCOUNT_QUERY);

  useEffect(() => {
    const getLink = async() => {
      const stripeLink = await getStripeLink();
      window.location = stripeLink;
    };

    if (!loading && !error && data?.getStripeAccount) {
      const { requirements } = data.getStripeAccount;

      if (requirements.disabled_reason !== '') {
        fail("Problem!", "It looks like Stripe was not able to set up your account properly. Please contact Stripe for support.");
        setTimeout(() => window.location = '/app/settings/organizer', 5);
      } else {
        getLink();
      }
    }
  }, [loading, error, data, getStripeLink]);

  if (loading) return <PSLoader />;

  if (error) window.location = '/app/settings/organizer';

  return(
    <></>
  )
}