import { useState, useCallback } from "react";
import {
    createStyles, 
    Box,
    Button,
    Checkbox,
    Stack,
    SimpleGrid,
    Group,
    Select,
    TextInput,
    NumberInput,
    rem,
} from '@mantine/core';
import PSCard from "@/components/PSCard";
import { success, fail, notice } from '../../lib/notifications';
import { DateTimePicker } from "@mantine/dates";
import { useMutation } from "graphql-hooks";
import { CREATE_TIER_MUTATION, UPDATE_TIER_MUTATION } from '../../network/mutations';
import { convertDate, errorMsg } from "../../lib/helpers";

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },
  input: {
    height: rem(54),
    paddingTop: rem(18),
    marginBottom: rem(10)
  },
  label: {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: `calc(${theme.spacing.sm} / 2)`,
    zIndex: 1,
  },
}));

export default function Tier({ tier, shows, eventId, refetch, delTier }) {
  const { classes } = useStyles();
  const [name, setName] = useState(tier.name);
  const [price, setPrice] = useState(tier.price);
  const [currency, setCurrency] = useState(tier.currency);
  const [show, setShow] = useState(tier.show_id);
  const [byDonation, setByDonation] = useState(tier.byDonation);
  const [max, setMax] = useState(tier.max);
  const [free, setFree] = useState(tier.price === 0);
  const [scheduled, setScheduled] = useState(Boolean(tier.startTime) || Boolean(tier.endTime));
  const [startTime, setStartTime] = useState(tier.startTime ? convertDate(tier.startTime) : null);
  const [endTime, setEndTime] = useState(tier.endTime ? convertDate(tier.endTime) : null);
  const [hasMin, setHasMin] = useState(tier.minimum > 0);
  const [minimum, setMinimum] = useState(tier.minimum);
  const [modifyTier] = useMutation(UPDATE_TIER_MUTATION);
  const [createTier] = useMutation(CREATE_TIER_MUTATION);

  const handleCreateTier = async () => {
    const fields = { name, price, currency, showId: show, cap: max, byDonation, eventId, minimum };
    const title = 'Uh Oh!';

    if (scheduled) {
      fields.start_time = startTime;
      fields.end_time = endTime;
    }

    if (name === '') {
      const message = 'Name cannot be blank';
      fail(title, message);
      return false;
    }

    if (max === 0) {
      const message = `You must offer at least 1 ticket at this tier.`;
      fail(title, message);

      return false;
    }

    if (show === null || show === undefined) {
      const message = "You must select a show for this tier.";
      fail(title, message);

      return false;
    }

    const results = await createTier({variables: fields});

    if (results.data?.createTier) {
      tier.id = results.data.createTier.id;
      success('Success!', 'Tier created successfully');
      refetch();
    } else {
      errorMsg(results, 'tier', 'create');
    }
  };

  const handleUpdateTier = useCallback(() => {
    const fields = { name, price, currency, show_id: show, max, byDonation, minimum };

    if (scheduled) {
      fields.start_time = startTime;
      fields.end_time = endTime;
    }

    if (name === '') {
      const message = "Name cannot be blank.";
      fail('Uh Oh!', message);
      return false;
    }

    const dirty = Object.keys(fields).filter(f => tier[f] !== fields[f]);

    //Change this here otherwise it triggers dirty checking.
    fields.price *= 100;

    if (dirty.length) {
      const map = {
        name: 'tier name',
        price: 'price',
        currency: 'currency',
        show_id: 'show',
        byDonation: 'tier donation status',
        max: 'capacity',
        start_time: 'start time',
        end_time: 'end time',
        minimum: 'minimum donation'
      };

      for (let field of dirty) {
        (async() => {
          if (fields[field] === true || fields[field] === false || fields[field] === 0) {
            fields[field] = fields[field].toString();
          }

          if (field === 'price' || field === 'max' || field === 'minimum') {
            fields[field] = fields[field].toString();
          }

          const results = await modifyTier({variables: {id: tier.id, field: (field === 'byDonation') ? 'by_donation' : field, value: fields[field]}});

          if (results.data.modifyTier) {
            tier[field] = fields[field];
            success('Tier Updated', `Updated ${map[field]} successfully.`);
          } else {
            errorMsg(results, 'tier', 'update');
          }
        })();
      }
    } else {
      notice('No Changes', 'The tier has not changed - Nothing to update');
    }
  }, [name, scheduled, price, currency, show, max, byDonation, tier, startTime, endTime, minimum, modifyTier]);

  const handleFree = useCallback((val) => {
    if (val) {
      setPrice(0);
    } else {
      setPrice(tier.price);
    }

    setFree(val);
  }, [setPrice, tier.price]);

  const handleMinimum = useCallback((val) => {
    if (val) {
      setMinimum(0);
    } else {
      setMinimum(tier.minimum);
    }

    setHasMin(val);
  }, [setMinimum, tier.minimum]);

  return (
    <PSCard>
      <Stack>
        <TextInput
          className={classes}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Ex: General, VIP, etc"
          size="md"
          label="Name"
        />
        <SimpleGrid cols={2}>
          <Checkbox
            label="By Donation"
            color="orange"
            size="md"
            description="Tickets are by donation"
            checked={byDonation}
            onChange={(e) => setByDonation(e.currentTarget.checked)}
          />
          <Checkbox
            label="Free Tier"
            color="orange"
            size="md"
            description="Tickets in this tier are free of charge"
            checked={free}
            onChange={(e) => handleFree(e.currentTarget.checked)}
          />
          <NumberInput
            className={classes}
            label="Tier Price"
            disabled={byDonation || free}
            placeholder="Ticket price"
            value={price}
            onChange={setPrice}
          />
          <Select
            label="Select currency"
            placeholder="Select currency"
            disabled={byDonation || free}
            value={currency}
            data={[{value: "CAD", label: "Canadian Dollar (CAD)"}, {value: "USD", label: "US Dollar (USD)"}]}
            onChange={setCurrency}
          />
          <Checkbox
            label="Minimum donation"
            color="orange"
            size="md"
            disabled={!byDonation}
            description="Required minimum donation"
            checked={hasMin}
            onChange={(e) => handleMinimum(e.currentTarget.checked)}
          />
          <NumberInput
            className={classes}
            label="Minimum amount"
            disabled={!byDonation}
            placeholder="Minimum"
            value={minimum}
            onChange={setMinimum}
          />
        </SimpleGrid>
        <Select
          label="Select a show"
          placeholder="Select a show"
          value={show}
          data={[{value: 0, label: "All shows"}, ...shows.map(s => { return { value: s.id, label: s.subtitle ? s.subtitle : convertDate(s.startTime)}})]}
          onChange={setShow}
        />
        <NumberInput
          className={classes}
          label="Capacity"
          placeholder="Max to sell"
          value={max}
          onChange={setMax}
        />
        <Checkbox
          label="Scheduled Tier"
          color="orange"
          size="md"
          description="Set start and end times for tier being live"
          checked={scheduled}
          onChange={(e) => setScheduled(e.currentTarget.checked)}
        />
        {scheduled && (
          <Box>
            <DateTimePicker
              popoverProps={{ withinPortal: true }}
              mb={10}
              className={classes}
              clearable
              valueFormat="MMM DD, YYYY hh:mm A"
              label="Set date and time for when tier takes effect (optional)"
              placeholder="Pick date and time"
              maw={400}
              size="md"
              value={new Date(startTime)}
              onChange={setStartTime}
              mx="auto"
            />
            <DateTimePicker
              className={classes}
              popoverProps={{ withinPortal: true }}
              clearable
              valueFormat="MMM DD, YYYY hh:mm A"
              label="Set date and time for when tier should stop (optional)"
              placeholder="Pick date and time"
              maw={400}
              size="md"
              value={endTime ? new Date(endTime) : undefined}
              onChange={setEndTime}
              mx="auto"
            />
        </Box>)}
        <Group position="apart">
          <Button color="orange" onClick={tier.id === 0 ? handleCreateTier : handleUpdateTier}>{tier.id === 0 ? 'Create' : 'Update'} Tier</Button>
          <Button color="red" onClick={() => delTier(tier.id) }>Delete Tier</Button>
        </Group>
      </Stack>
    </PSCard>
  )
}